import React from 'react';
import { LoadingProvider, AlertProvider, PayInfoProvider } from './providers';

const Provider = (props) => {
  const { children } = props;

  return (
    // Global
    <LoadingProvider>
      <AlertProvider>
        <PayInfoProvider>{children}</PayInfoProvider>
      </AlertProvider>
    </LoadingProvider>
  );
};

export default Provider;
