import React, { useState, useEffect, useRef } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import API from '../api';
import Utils from '../utils';
import queryString from 'query-string';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Picker from 'react-month-picker';
import 'react-month-picker/css/month-picker.css';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';

const ColorCircularProgress = withStyles((theme) => ({
  colorPrimary: {
    color: '#6e95dd',
  },
}))(CircularProgress);

const WhiteCircularProgress = withStyles((theme) => ({
  colorPrimary: {
    color: '#ffffff',
  },
}))(CircularProgress);

const listToGroupData = (list) => {
  console.log('listToGroupData');
  let lastYYYYMM = null;
  let newObj = {};
  for (let i in list) {
    let listItem = list[i];
    const targetYYYYMM = listItem.transfer_reg_dt.substr(0, 7);
    if (lastYYYYMM) {
      if (lastYYYYMM === targetYYYYMM) {
        newObj[targetYYYYMM].push(list[i]);
      } else {
        lastYYYYMM = targetYYYYMM;
        newObj[targetYYYYMM] = [list[i]];
      }
    } else {
      lastYYYYMM = targetYYYYMM;
      newObj[targetYYYYMM] = [list[i]];
    }
  }
  return newObj;
};

function leadingZeros(n, digits) {
  var zero = '';
  n = n.toString();

  if (n.length < digits) {
    for (let i = 0; i < digits - n.length; i++) zero += '0';
  }
  return zero + n;
}

function getTimeStamp(date) {
  var d = date;
  var s =
    leadingZeros(d.getFullYear(), 4) +
    '-' +
    leadingZeros(d.getMonth() + 1, 2) +
    '-' +
    leadingZeros(d.getDate(), 2) +
    ' ' +
    leadingZeros(d.getHours(), 2) +
    ':' +
    leadingZeros(d.getMinutes(), 2) +
    ':' +
    leadingZeros(d.getSeconds(), 2);

  return s;
}

const getYearMonth = (monthValue) => {
  var s = leadingZeros(monthValue.year) + '-' + leadingZeros(monthValue.month, 2) + '-01' + ' 00:00:00';

  return s;
};
const pickerLang = {
  months: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
};
export const WalletPage = (props) => {
  const now = new Date();
  const history = useHistory();
  const [listType, setListType] = useState('all');
  const [serviceGroupType, setServiceGroupType] = useState(null);
  const [serviceGroupList, setServiceGroupList] = useState([]);
  const location = useLocation();
  const queryObject = queryString.parse(location.search);

  const [balanceInfo, setBalanceInfo] = useState(null);
  const [transferList, setTransferList] = useState(null);
  const [loading, setLoading] = useState(false);
  const [noMore, setNoMore] = useState(false);
  const [displayList, setDisplayList] = useState({});
  const reactDatePicker = useRef(null);
  const monthPicker = useRef(null);
  const [monthValue, setMonthValue] = useState({
    year: now.getFullYear(),
    month: now.getMonth() + 1,
  });
  const [isAllMonth, setIsAllMonth] = useState(true);
  useEffect(() => {
    console.log('queryObject', queryObject);

    API.Wallet.getBalance(queryObject).then(
      (res) => {
        let result = API.Utils.ResultCheck(res);
        console.log('getBalance result', result);
        let newBalanceInfo = {
          current: Utils.pebIntToKlay(result.currentBalance),
          reward: Utils.pebIntToKlay(result.rewardBalance),
        };
        setBalanceInfo(newBalanceInfo);
      },
      (err) => {
        console.log('getBalance err', err);
        Utils.errorHandler(queryObject, err);
      },
    );

    API.Wallet.getSerivceGroupList().then(
      (res) => {
        let result = API.Utils.ResultCheck(res);
        console.log('getSerivceGroupList result', result);
        if (result) {
          let newServiceGroupList = [];
          let allServiceGroupValue = '';
          let serviceGroupList = result.list;
          for (let i in serviceGroupList) {
            if (serviceGroupList[i].svc_grp_id === 'test-sg') {
            } else {
              newServiceGroupList.push({
                value: serviceGroupList[i].svc_grp_id,
                text: serviceGroupList[i].name,
              });
              allServiceGroupValue += serviceGroupList[i].svc_grp_id + ',';
            }
          }
          newServiceGroupList.unshift({
            text: '전체',
            value: allServiceGroupValue,
          });

          setServiceGroupList(newServiceGroupList);
          setServiceGroupType(allServiceGroupValue);
        }
      },
      (err) => {
        console.log('getSerivceGroupList err', err);
        Utils.errorHandler(queryObject, err);
      },
    );
  }, []);

  useEffect(() => {}, []);

  useEffect(() => {
    console.log('useEffect listType', listType);
    console.log('useEffect isAllMonth', isAllMonth);
    console.log('useEffect monthValue', monthValue);
    console.log('useEffect serviceGroupType', serviceGroupType);

    if (!serviceGroupType) {
      console.log('serviceGroupType wait');
      return;
    }
    let transferParams = {
      memberId: queryObject.memberId,
      memberGroupId: queryObject.memberGroupId,
      serviceGroupId: serviceGroupType,
      fromDate: isAllMonth ? 0 : getYearMonth(monthValue),
      offset: 0,
      type: listType,
      token: queryObject.token,
    };
    setLoading(true);
    setTransferList([]);
    setNoMore(false);
    API.Wallet.getTransfer(transferParams).then(
      (res) => {
        let result = API.Utils.ResultCheck(res);
        console.log('getTransfer result', result);
        setTransferList([...result.list]);
        setLoading(false);
        if (result.list.length === 0) {
          setNoMore(true);
        }
      },
      (err) => {
        console.log('getTransfer err', err);
        Utils.errorHandler(queryObject, err);
      },
    );
  }, [listType, isAllMonth, monthValue, serviceGroupType]);

  useEffect(() => {
    console.log('transferList', transferList);
  }, [transferList]);

  const handleChange = (e) => {
    console.log('handleChange', e.target.value);
    setListType(e.target.value);
  };

  const handleChangeServiceGroup = (e) => {
    console.log('handleChange', e.target.value);
    setServiceGroupType(e.target.value);
  };

  console.log('balanceInfo', balanceInfo);
  const onYReachEnd = () => {
    console.log('onYReachEnd');
    if (!transferList) {
      return;
    }
    if (transferList.length === 0 || transferList.length < 10) {
      return;
    }
    if (noMore) {
      console.log('no More');
      return;
    }
    if (loading) {
      console.log('return now loading');
      return;
    } else {
      console.log('setLoading');
      setLoading(true);
    }
    console.log('onYReachEnd request more list');
    let transferParams = {
      memberId: queryObject.memberId,
      memberGroupId: queryObject.memberGroupId,
      serviceGroupId: serviceGroupType,
      fromDate: isAllMonth ? 0 : getYearMonth(monthValue),
      offset: parseInt(transferList.length),
      type: listType,
      token: queryObject.token,
    };
    API.Wallet.getTransfer(transferParams).then(
      (res) => {
        let result = API.Utils.ResultCheck(res);
        console.log('getTransfer result', result);
        setTransferList([...transferList, ...result.list]);
        setLoading(false);
        if (result.list.length === 0) {
          setNoMore(true);
        }
      },
      (err) => {
        setLoading(false);
        console.log('getTransfer err', err);
        Utils.errorHandler(queryObject, err);
      },
    );
  };

  console.log('transferList', transferList);
  console.log('displayList', displayList);

  const requestRefresh = () => {
    setBalanceInfo(null);
    API.Wallet.getBalance(queryObject).then(
      (res) => {
        let result = API.Utils.ResultCheck(res);
        console.log('getBalance result', result);
        let newBalanceInfo = {
          current: Utils.pebIntToKlay(result.currentBalance),
          reward: Utils.pebIntToKlay(result.rewardBalance),
        };
        setBalanceInfo(newBalanceInfo);
      },
      (err) => {
        console.log('setBalanceInfo err', err);
        Utils.errorHandler(queryObject, err);
      },
    );
  };
  console.log('balanceInfo', balanceInfo);
  const handleAMonthChange = (year, month) => {
    console.log('handleAMonthChange', year);
    console.log('handleAMonthChange', month);
    setMonthValue({
      year: year,
      month: month,
    });
    setIsAllMonth(false);
    monthPicker.current.dismiss();
  };

  const handleAMonthDissmis = (value) => {
    console.log('handleAMonthDissmis', value);
    setMonthValue(value);
    setIsAllMonth(false);
  };

  return (
    <article className="wallet-page">
      <PerfectScrollbar onYReachEnd={onYReachEnd}>
        <section className="info__box">
          {!balanceInfo ? (
            <article className="klay-card">
              <div className="loading__box">
                <WhiteCircularProgress />
              </div>
            </article>
          ) : (
            <article className="klay-card">
              <div className="baisc-info">
                <span>
                  {/* <div className="ico klay" style={{ marginRight: 5 }}></div>나의 클레이 */}
                  나의 클레이
                </span>
                {/* <div onClick={requestRefresh} className="ico refresh"></div> */}
                <div className="abs">
                  <div onClick={requestRefresh} className="ico refresh-circle"></div>
                </div>
              </div>
              <div className="balance">
                <div className="value">{balanceInfo && balanceInfo.current}</div>
                <span className="unit">KLAY</span>
              </div>
              <div className="reward">
                <b>적립예정 클레이</b>
                <div
                  className="value"
                  onClick={() => {
                    history.push({
                      pathname: 'reward',
                      search: new URLSearchParams(queryObject).toString(),
                    });
                  }}
                >
                  <span>
                    {balanceInfo && balanceInfo.reward}
                    <em>KLAY</em>
                  </span>
                  <div className="ico arrow-right-white"></div>
                </div>
              </div>
            </article>
          )}
        </section>
        <section className="transfer-header">
          <h1>이용 내역 조회</h1>
          <div className="filter__box">
            <section className="month__box">
              <ul>
                <li
                  className={isAllMonth ? 'on' : ''}
                  onClick={() => {
                    setIsAllMonth(true);
                  }}
                >
                  <span>전체</span>
                </li>
                <li className={isAllMonth ? '' : 'on'}>
                  <span
                    onClick={() => {
                      monthPicker.current.show();
                    }}
                  >
                    월별
                  </span>
                </li>
              </ul>
            </section>
            <section className="type__box">
              <FormControl variant="outlined" style={{ marginRight: 10, minWidth: 100 }}>
                <InputLabel shrink id={'table-header-label-sg'} variant="outlined">
                  계열사
                </InputLabel>
                <Select
                  displayEmpty={true}
                  value={serviceGroupType}
                  onChange={handleChangeServiceGroup}
                  // label="계열사"
                  // labelId="table-header-label-sg"
                  input={<OutlinedInput notched label={'계열사'} />}
                >
                  {serviceGroupList.map((item, index) => {
                    return (
                      <MenuItem key={'service_group-key-' + index} value={item.value}>
                        {item.text}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <FormControl variant="outlined">
                <InputLabel id={'table-header-label'}>타입</InputLabel>
                <Select value={listType} onChange={handleChange} label="타입">
                  <MenuItem value={'all'}>전체</MenuItem>
                  <MenuItem value={'rwd'}>적립</MenuItem>
                  <MenuItem value={'pay'}>사용</MenuItem>
                </Select>
              </FormControl>
            </section>
          </div>
        </section>
        {/* <section className="month-picker__box">
          <div className="custom-month-picker">
            <div className="show-data">
              <div
                className="ico arrow-button-left"
                style={{ marginRight: 40 }}
                onClick={() => {
                  let oneMonthAgo = new Date(startDate.getFullYear(), startDate.getMonth() - 1, startDate.getDate());
                  setStartDate(oneMonthAgo);
                }}
              ></div>
              <div className="react-date-picker__box">
                <DatePicker
                  ref={reactDatePicker}
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  dateFormat="yyyy년 MM월"
                  showMonthYearPicker
                />
              </div>
              <div
                className="ico arrow-button-right"
                style={{ marginLeft: 40 }}
                onClick={() => {
                  let oneMonthAgo = new Date(startDate.getFullYear(), startDate.getMonth() + 1, startDate.getDate());
                  setStartDate(oneMonthAgo);
                }}
              ></div>
            </div>
            <div className="right__box">
              <div
                className="ico calendar"
                onClick={() => {
                  console.log('reactDatePicker.current', reactDatePicker.current);
                  reactDatePicker.current.setOpen(true);
                  monthPicker.current.show();
                }}
              ></div>
            </div>
          </div>
        </section> */}
        <section>
          <Picker
            ref={monthPicker}
            years={[2021, 2022, 2023, 2024, 2025]}
            value={monthValue}
            lang={pickerLang.months}
            onChange={handleAMonthChange}
            // onDismiss={handleAMonthDissmis}
          ></Picker>
        </section>
        <section className="transfer-list__box">
          {/* {displayList &&
            Object.keys(displayList).map((key) => {
              return (
                <ul>
                  <header>{Utils.YYYYMMToKorean(key)}</header>
                  {displayList[key].map((row, index) => {
                    return (
                      <li className={row.type}>
                        <div className="main">
                          <span>{row.name}</span>
                          <b>
                            {row.type === 'rwd' ? '+' : '-'}
                            {Utils.pebIntToKlay2(row.amount)}
                            <em> KLAY</em>
                          </b>
                        </div>
                        <div className="sub">
                          <span>
                            {row.mbr_grp_id === 'hk' ? '한경통합' : '한경TV'}{' '}
                            {Utils.dbDateToMMDD(row.transfer_reg_dt)}
                          </span>
                          <span>{row.type === 'rwd' ? '적립' : '사용'}</span>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              );
            })} */}
          {transferList && transferList.length > 0 && (
            <ul>
              {transferList.map((row, index) => {
                return (
                  <li className={row.type}>
                    <div className="header">
                      <span>{Utils.dbDateToMMDD(row.transfer_reg_dt)}</span>
                    </div>
                    <div className="sub">
                      <span>{row.mbr_grp_id === 'hk' ? '한경미디어그룹' : '한경TV'} </span>
                      <span className={row.type}>{row.type === 'rwd' ? '적립' : '사용'}</span>
                    </div>
                    <div className="main">
                      <span>{row.name}</span>
                      <b>
                        {row.type === 'rwd' ? '+' : '-'}
                        {Utils.pebIntToKlay2(row.amount)}
                        <em> KLAY</em>
                      </b>
                    </div>
                  </li>
                );
              })}
            </ul>
          )}
          {transferList && transferList.length === 0 && !loading && (
            <div className="empty__box">
              <div className="ico info-blue"></div>
              <span style={{ marginTop: 20 }}>거래내역이 없습니다.</span>
            </div>
          )}
        </section>
        {loading && (
          <div className="loading__box">
            <ColorCircularProgress />
          </div>
        )}
      </PerfectScrollbar>
    </article>
  );
};
