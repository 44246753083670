import React, { useState } from 'react';
import { PayInfoContext } from '../contexts';

export const PayInfoProvider = (props) => {
  const { children } = props;
  const [payInfo, setPayInfo] = useState(false);

  const updatePayInfo = (value) => {
    setPayInfo(value);
  };

  return (
    <PayInfoContext.Provider
      value={{
        payInfo: payInfo,
        updatePayInfo: updatePayInfo,
      }}
    >
      {children}
    </PayInfoContext.Provider>
  );
};
