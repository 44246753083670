import axios from 'axios';
import { BASE_URL } from './Config';
import { Base64 } from 'js-base64';

const AxiosInstance = axios.create({
  baseURL: BASE_URL,
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json;charset=utf-8',
    // 'x-api-key': process.env.REACT_APP_API_KEY,
  },
});

const checkSearchStringExist = (str) => {
  const splitStringList = str.split('?');
  if (splitStringList.length === 1) {
    return false;
  } else {
    return true;
  }
};

// 요청 인터셉터 추가
AxiosInstance.interceptors.request.use(
  (config) => {
    console.log('interceptors request config', config);

    //시스템 점검용 config
    // if (checkSearchStringExist(config.url)) {
    //   config.url = config.url + '&pass=joy';
    // } else {
    //   config.url = config.url + '?&pass=joy';
    // }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

// 응답 인터셉터 추가
AxiosInstance.interceptors.response.use(
  (response) => {
    // console.log('interceptors response', response);
    const maintenanceString = response.headers['maintenance'];
    console.log('maintenanceString', maintenanceString);

    if (maintenanceString) {
      let decode = JSON.parse(Base64.decode(maintenanceString));
      console.log('decode', decode);
      const urlParams = new URLSearchParams(window.location.search);
      const failUrl = urlParams.get('failUrl');
      const webView = urlParams.get('webView');

      window.location.href = `/maintenance?message=${encodeURIComponent(
        decode.message,
      )}&failUrl=${failUrl}&webView=${webView}`;
    } else {
      return response;
    }
  },
  (error) => {
    console.log('interceptors error', error.response);
    const maintenanceString = error.response.headers['maintenance'];
    console.log('maintenanceString', maintenanceString);

    if (maintenanceString) {
      let decode = JSON.parse(Base64.decode(maintenanceString));
      console.log('decode', decode);
      const urlParams = new URLSearchParams(window.location.search);
      const failUrl = urlParams.get('failUrl');
      const webView = urlParams.get('webView');
      window.location.href = `/maintenance?message=${encodeURIComponent(
        decode.message,
      )}&failUrl=${failUrl}&webView=${webView}`;
    } else {
      return Promise.reject(error);
    }
  },
);

export default AxiosInstance;
