import React, { useEffect, useContext } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import API from '../api';
import queryString from 'query-string';
import { LoadingContext } from '../contexts';
import Utils from '../utils';
import { request } from 'klip-sdk';

const checkSearchStringExist = (str) => {
  const splitStringList = str.split('?');
  if (splitStringList.length === 1) {
    return false;
  } else {
    return true;
  }
};

export const ResultPage = (props) => {
  const history = useHistory();
  const location = useLocation();
  const queryObject = queryString.parse(location.search);
  const { updateLoading } = useContext(LoadingContext);

  const requestConfirm = () => {
    updateLoading(true);
    API.Link.Confirm(queryObject).then(
      (res) => {
        updateLoading(false);

        console.log('res', res);

        const result = API.Utils.ResultCheck(res);
        console.log('result', result);
        if (result && result.status === 'completed') {
          alert('클립 연결이 성공적으로 이루어졌습니다.');
          console.log('queryObject', queryObject);
          console.log('queryObject.redirectUrl', queryObject.redirectUrl);
          if (queryObject.redirectUrl) {
            console.log(' if queryObject.redirectUrl', queryObject.redirectUrl);

            let requestRedirectUrl = decodeURIComponent(queryObject.redirectUrl);
            const searchStringExist = checkSearchStringExist(requestRedirectUrl);
            let infoString = null;

            if (result && result.info) {
              infoString = 'memberId=' + res.data.info.mbr_id + '&isNew=' + res.data.info.klip_new;
              console.log(' if infoString.redirectUrl', infoString);
            }
            if (infoString) {
              if (searchStringExist) {
                requestRedirectUrl = requestRedirectUrl + '&' + infoString;
              } else {
                requestRedirectUrl = requestRedirectUrl + '?' + infoString;
              }
            }
            console.log(' if requestRedirectUrl', requestRedirectUrl);

            if (queryObject.webView) {
              console.log(' webView request', queryObject.webView);
              window.location.href = requestRedirectUrl;
            } else {
              console.log(' else work');
              if (window.opener && window.opener.location) {
                console.log(' window.opener');
                window.opener.location.href = requestRedirectUrl;
                window.close();
              } else {
                console.log(' window.opener else');
                window.location.href = requestRedirectUrl;
                window.close();
              }
            }
          } else {
            Utils.windowClose(queryObject);
          }
        } else {
          alert('아직 처리되지 않았습니다.(' + result.status + ')');
        }
      },
      (err) => {
        updateLoading(false);

        console.log('err', err);
        if (err.response && err.response.data && err.response.data.code === 1013) {
          Utils.errorHandler(queryObject, err);
        } else if (err.response && err.response.data && err.response.data.message) {
          alert(err.response.data.message);
          history.push({
            pathname: 'retry',
            search: new URLSearchParams(queryObject).toString(),
          });
        } else {
          history.push({
            pathname: 'retry',
            search: new URLSearchParams(queryObject).toString(),
          });
        }
      },
    );
  };

  return (
    <article className="result-page">
      <section className="message__box">
        <div className="img__box">
          <section className="logo">
            <div className="item logo-hankyungmedia"></div>
          </section>
          <section className="check">
            <div className="abs">
              <div className="item coin-small"></div>
            </div>
            <div className="item link-check"></div>
          </section>
        </div>
        <div className="text__box">
          <h1>
            클립(Klip) 연결 후,<br></br>
            연결확인 버튼을 눌러주세요.
          </h1>
        </div>
      </section>
      <section className="button__box">
        <button
          onClick={() => {
            requestConfirm();
          }}
        >
          연결확인
        </button>
        <button
          style={{ marginTop: 20 }}
          className="cancel"
          onClick={() => {
            Utils.windowClose(queryObject);
          }}
        >
          닫기
        </button>
      </section>
    </article>
  );
};
