import React, { useEffect, useContext } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import API from '../api';
import queryString from 'query-string';
import { LoadingContext, PayInfoContext } from '../contexts';
import Utils from '../utils';
import QRCode from 'react-qr-code';

const checkSearchStringExist = (str) => {
  const splitStringList = str.split('?');
  if (splitStringList.length === 1) {
    return false;
  } else {
    return true;
  }
};

export const QrResultPage = (props) => {
  const history = useHistory();
  const location = useLocation();
  const queryObject = queryString.parse(location.search);
  const { updateLoading } = useContext(LoadingContext);
  const type = queryObject.type;
  const { payInfo } = useContext(PayInfoContext);

  const requestConfirm = () => {
    updateLoading(true);
    API.Link.Confirm(queryObject).then(
      (res) => {
        updateLoading(false);

        console.log('res', res);

        const result = API.Utils.ResultCheck(res);
        console.log('result', result);
        if (result && result.status === 'completed') {
          alert('클립 연결이 성공적으로 이루어졌습니다.');

          if (queryObject.redirectUrl) {
            let requestRedirectUrl = decodeURIComponent(queryObject.redirectUrl);
            const searchStringExist = checkSearchStringExist(requestRedirectUrl);
            let infoString = null;

            if (result && result.info) {
              infoString = 'memberId=' + res.data.info.mbr_id + '&isNew=' + res.data.info.klip_new;
            }
            if (infoString) {
              if (searchStringExist) {
                requestRedirectUrl = requestRedirectUrl + '&' + infoString;
              } else {
                requestRedirectUrl = requestRedirectUrl + '?' + infoString;
              }
            }
            if (queryObject.webView) {
              window.location.href = requestRedirectUrl;
            } else {
              if (window.opener && window.opener.location) {
                window.opener.location.href = requestRedirectUrl;
                window.close();
              } else {
                window.location.href = requestRedirectUrl;
                window.close();
              }
            }
          } else {
            Utils.windowClose(queryObject);
          }
        } else {
          alert('아직 처리되지 않았습니다.(' + result.status + ')');
        }
      },
      (err) => {
        updateLoading(false);
        console.log('err', err);
        if (err.response && err.response.data && err.response.data.code === 1013) {
          Utils.errorHandler(queryObject, err);
        } else if (err.response && err.response.data && err.response.data.message) {
          alert(err.response.data.message);
          history.push({
            pathname: 'retry',
            search: new URLSearchParams(queryObject).toString(),
          });
        } else {
          history.push({
            pathname: 'retry',
            search: new URLSearchParams(queryObject).toString(),
          });
        }
      },
    );
  };

  const requestConfirmPay = () => {
    updateLoading(true);

    console.log('requestConfirmPay,');
    API.Pay.Confirm(queryObject).then(
      (res) => {
        updateLoading(false);
        console.log('res', res);
        const result = API.Utils.ResultCheck(res);
        console.log('result', result);
        if (result.status && result.status === 'success') {
          alert('결제가 성공적으로 이루어졌습니다.');
          let params = {
            tansferSequence: queryObject.tansferSequence,
          };

          if (payInfo) {
            let payObject = Utils.payInfoToParams(payInfo);
            console.log('payObject', payObject);
            params = { ...params, ...payObject };
          }
          Utils.requestSuccess(queryObject, params);
        } else {
          alert('아직 처리되지 않았습니다.(' + result.status + ')');
        }
      },
      (err) => {
        console.log('err', err);
        updateLoading(false);
        console.log('err', err);
        const response = err.response.data;
        const code = response.code;
        const message = response.message;

        let errorParams = {
          ...queryObject,
          message: message,
          code: code,
        };
        if (code === '6411' || code === 6411) {
          errorParams.message = '정상적인 거래를 위해서 클립접속이 필요 할수 있습니다.'
        }
        let searchString = new URLSearchParams(errorParams).toString();
        history.push({
          pathname: 'error',
          search: searchString,
        });
      },
    );
  };

  return (
    <article className="result-page">
      <section className="message__box">
        <div className="img__box">
          <section className="logo">
            <div className="item logo-hankyungmedia"></div>
          </section>
          <section className="qr_code__box">
            <QRCode
              size={160}
              value={`https://klipwallet.com/?target=/a2a?request_key=${queryObject.requestKey}`}
            ></QRCode>
          </section>
        </div>
        <div className="text__box qr-code">
          {type === 'pay' ? (
            <>
              <div className="text-item">
                <div className="red-dot">
                  <div className="circle"></div>
                </div>
                <h2>QR코드를 스마트폰으로 스캔하면 카카오톡 클립(Klip)으로 연결됩니다.</h2>
              </div>
              <div className="text-item">
                <div className="red-dot">
                  <div className="circle"></div>
                </div>
                <h2>클립(Klip) 연결 후 결제확인 버튼을 눌러주세요.</h2>
              </div>
            </>
          ) : (
              <>
                <div className="text-item">
                  <div className="red-dot">
                    <div className="circle"></div>
                  </div>
                  <h2>QR코드를 스마트폰으로 스캔하면 카카오톡 클립(Klip)으로 연결됩니다.</h2>
                </div>
                <div className="text-item">
                  <div className="red-dot">
                    <div className="circle"></div>
                  </div>
                  <h2>클립(Klip) 연결 후 연결확인 버튼을 눌러주세요.</h2>
                </div>
              </>
            )}
        </div>
      </section>
      <section className="button__box">
        {type === 'pay' ? (
          <button onClick={requestConfirmPay}>결제확인</button>
        ) : (
            <button onClick={requestConfirm}>연결확인</button>
          )}
      </section>
    </article>
  );
};
