import React, { useEffect, useContext } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import API from '../api';
import queryString from 'query-string';
import { LoadingContext, PayInfoContext } from '../contexts';
import Utils from '../utils';

const checkSearchStringExist = (str) => {
  const splitStringList = str.split('?');
  if (splitStringList.length === 1) {
    return false;
  } else {
    return true;
  }
};

export const PayResultPage = (props) => {
  const history = useHistory();
  const location = useLocation();
  const queryObject = queryString.parse(location.search);
  const { updateLoading } = useContext(LoadingContext);
  const { payInfo } = useContext(PayInfoContext);

  console.log('queryObject', queryObject);
  useEffect(() => {}, []);
  const requestConfirm = () => {
    updateLoading(true);

    API.Pay.Confirm(queryObject).then(
      (res) => {
        updateLoading(false);
        console.log('res', res);
        const result = API.Utils.ResultCheck(res);
        console.log('result', result);
        if (result.status && result.status === 'success') {
          alert('결제가 성공적으로 이루어졌습니다.');
          let params = {
            tansferSequence: queryObject.tansferSequence,
          };
          if (payInfo) {
            let payObject = Utils.payInfoToParams(payInfo);
            console.log('payObject', payObject);
            params = { ...params, ...payObject };
          }
          Utils.requestSuccess(queryObject, params);
        } else {
          alert('아직 처리되지 않았습니다.(' + result.status + ')');
        }
      },
      (err) => {
        console.log('err', err);
        updateLoading(false);
        Utils.errorHandler(queryObject, err);
      },
    );
  };

  return (
    <article className="result-page">
      <section className="message__box">
        <div className="text__box" style={{ flex: 1, alignItems: 'center' }}>
          <h1 style={{ height: 70 }}>
            클레이(Klay) 전송 후,<br></br>
            <em style={{ fontStyle: 'normal' }}>결제확인</em> 버튼을 눌러주세요.
          </h1>
        </div>
        <div className="button__box">
          <button onClick={requestConfirm}>결제확인</button>
          <button
            style={{ marginTop: 20 }}
            className="cancel"
            onClick={() => {
              API.Pay.Confirm(queryObject).then(
                (res) => {
                  updateLoading(false);
                  console.log('res', res);
                  const result = API.Utils.ResultCheck(res);
                  console.log('result', result);
                  //닫기 시에는 success일 경우엔 redirect 아닌 경우엔 닫기를 수행해야함.
                  if (result.status && result.status === 'success') {
                    alert('결제가 성공적으로 이루어졌습니다.');
                    let params = {
                      tansferSequence: queryObject.tansferSequence,
                    };
                    if (payInfo) {
                      let payObject = Utils.payInfoToParams(payInfo);
                      console.log('payObject', payObject);
                      params = { ...params, ...payObject };
                    }
                    Utils.requestSuccess(queryObject, params);
                  } else {
                    let params = {
                      tansferSequence: queryObject.tansferSequence,
                    };
                    if (payInfo) {
                      let payObject = Utils.payInfoToParams(payInfo);
                      params = { ...params, ...payObject };
                    }
                    Utils.windowClose(queryObject, params);
                  }
                },
                (err) => {
                  console.log('err', err);
                  updateLoading(false);
                  Utils.errorHandler(queryObject, err);
                },
              );
            }}
          >
            닫기
          </button>
        </div>
      </section>
    </article>
  );
};
