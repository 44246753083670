import React, { useContext, useEffect, useState } from 'react';
import API from '../api';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import queryString from 'query-string';
import { LoadingContext } from '../contexts';
import Utils from '../utils';
import { request } from 'klip-sdk';
import Modal from 'react-modal';

const customStyles = {
  overlay: {
    zIndex: 10,
    backgorundColor: '#111',
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    height: '60%',
  },
};

const customStyles2 = {
  overlay: {
    zIndex: 10,
    backgorundColor: '#111',
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    height: '280px',
  },
};
function checkMobile() {
  var varUA = navigator.userAgent.toLowerCase(); //userAgent 값 얻기

  if (varUA.indexOf('android') > -1) {
    //안드로이드
    return 'android';
  } else if (varUA.indexOf('iphone') > -1 || varUA.indexOf('ipad') > -1 || varUA.indexOf('ipod') > -1) {
    //IOS
    return 'ios';
  } else {
    //아이폰, 안드로이드 외
    return 'other';
  }
}

export const AuthPage = (props) => {
  const history = useHistory();
  const location = useLocation();
  const deviceType = checkMobile();
  const queryObject = queryString.parse(location.search);
  const { updateLoading } = useContext(LoadingContext);
  const [isAggree, setIsAggree] = useState(false);
  const [isAgreePrivacy, setIsAgreePrivacy] = useState(false);
  const [isAgreeCollection, setIsAgreeCollection] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalPrivacyIsOpen, setModalPrivacyIsOpen] = useState(false);
  const [modalCollectionIsOpen, setModalCollectionIsOpen] = useState(false);

  const TV_GNB_LIST = [
    {
      id: 'hk_tv',
      text: '한국경제TV',
      color: '#5A69BD',
      url: 'https://m.wowtv.co.kr/',
      target: '_blank',
    },
    {
      id: 'wownet',
      text: '와우넷',
      color: '#F04F4F',
      url: 'https://m.wownet.co.kr/',
      target: '_blank',
    },
    {
      id: 'wowfa',
      text: '와우파',
      color: '#4992CF',
      url: 'https://www.wowfa.co.kr/',
      target: '_blank',
    },
    {
      id: 'kaly_event',
      text: 'KLAY 이벤트',
      color: '#3C2EDE',
      url: 'https://m.wowtv.co.kr/klay/Event',
      target: '_top',
    },
  ];

  useEffect(() => {
    Modal.setAppElement('body');
  }, []);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
  }

  function closeModal() {
    setIsOpen(false);
  }

  function openModalPrivacy() {
    setModalPrivacyIsOpen(true);
  }

  function afterOpenModalPrivacy() {
    // references are now sync'd and can be accessed.
  }

  function closeModalPrivacy() {
    setModalPrivacyIsOpen(false);
  }

  function openModalCollection() {
    setModalCollectionIsOpen(true);
  }

  function afterOpenModalCollection() {
    // references are now sync'd and can be accessed.
  }

  function closeModalCollection() {
    setModalCollectionIsOpen(false);
  }

  console.log('queryObject', queryObject);

  useEffect(() => {
    console.log('deviceType', deviceType);
  }, []);

  const requestKlip = () => {
    console.log('requestKlip');
    updateLoading(true);
    API.Link.Check(queryObject).then(
      (res) => {
        const result = API.Utils.ResultCheck(res);
        console.log('Check result', result);
        if (result) {
          // poll request and tehn deep link
          let pollParams = {
            ...queryObject,
            requestKey: result.prepareKey,
            tempLinkSequence: result.tempLinkSequence,
          };
          API.Link.AsyncPoll(pollParams).then(
            (res) => {
              console.log('AsyncPoll res', res);
              updateLoading(false);

              if (deviceType === 'other') {
                history.push({
                  pathname: 'qr_result',
                  search: new URLSearchParams(pollParams).toString(),
                });
              } else {
                history.push({
                  pathname: 'result',
                  search: new URLSearchParams(pollParams).toString(),
                });
                request(result.prepareKey);
              }
            },
            (err) => {
              updateLoading(false);
              Utils.errorHandler(queryObject, err);
            },
          );
        }
      },
      (err) => {
        updateLoading(false);
        console.log('err', err.response);

        Utils.errorHandler(queryObject, err);
      },
    );
  };

  return (
    <article className="auth-page" id="content-element">
      {queryObject.memberGroupId === 'tv' && (
        <section className="tv-gnb">
          <ul>
            {TV_GNB_LIST.map((item, index) => {
              return (
                <li key={'tv-gnv-' + index} style={{ backgroundColor: item.color }}>
                  <a target={item.target} href={item.url}>
                    <span>{item.text}</span>
                  </a>
                </li>
              );
            })}
          </ul>
        </section>
      )}

      <section className="scroll__container" style={{ WebkitOverflowScrolling: 'touch' }}>
        <article className="full-page">
          <div className="item logo_hk_klip" style={{ marginTop: 45 }}></div>
          <div className="item title_2" style={{ marginTop: 25 }}></div>
          <div className="bg__container">
            <section className={isAggree && isAgreeCollection && isAgreePrivacy ? 'button__box aggree' : 'button__box'}>
              <button
                style={{ marginBottom: 10, height: 36 }}
                onClick={() => {
                  setIsAggree(!isAggree);
                }}
              >
                <div className="button-text__box">
                  <div>
                    <div
                      className={isAggree ? 'ico aggree-checked' : 'ico aggree-check'}
                      style={{ marginRight: 10 }}
                    ></div>
                    이용자 약관 동의
                  </div>

                  <em
                    style={{ marginLeft: 10 }}
                    onClick={(e) => {
                      e.stopPropagation();
                      openModal();
                    }}
                  >
                    [상세보기]
                  </em>
                </div>
              </button>
              <button
                style={{ marginBottom: 10, height: 36 }}
                onClick={() => {
                  setIsAgreeCollection(!isAgreeCollection);
                }}
              >
                <div className="button-text__box">
                  <div>
                    <div
                      className={isAgreeCollection ? 'ico aggree-checked' : 'ico aggree-check'}
                      style={{ marginRight: 10 }}
                    ></div>
                    개인정보 수집 동의
                  </div>

                  <em
                    style={{ marginLeft: 10 }}
                    onClick={(e) => {
                      e.stopPropagation();
                      openModalCollection();
                    }}
                  >
                    [상세보기]
                  </em>
                </div>
              </button>
              <button
                style={{ marginBottom: 10, height: 36 }}
                onClick={() => {
                  setIsAgreePrivacy(!isAgreePrivacy);
                }}
              >
                <div className="button-text__box">
                  <div>
                    <div
                      className={isAgreePrivacy ? 'ico aggree-checked' : 'ico aggree-check'}
                      style={{ marginRight: 10 }}
                    ></div>
                    개인정보 처리방침 동의
                  </div>

                  <em
                    style={{ marginLeft: 10 }}
                    onClick={(e) => {
                      e.stopPropagation();
                      openModalPrivacy();
                    }}
                  >
                    [상세보기]
                  </em>
                </div>
              </button>
              <button
                className="klip"
                onClick={() => {
                  if (isAggree && isAgreePrivacy && isAgreeCollection) {
                    requestKlip();
                  } else {
                    alert('약관에 동의해주세요.');
                  }
                }}
              >
                {/* <div
                  className={isAggree && isAgreePrivacy && isAgreeCollection ? 'ico klip-checked' : 'ico klip-check'}
                  style={{ marginRight: 10 }}
                ></div> */}
                <div
                  className={isAggree && isAgreePrivacy && isAgreeCollection ? 'logo__box checked' : 'logo__box'}
                  style={{ marginRight: 10 }}
                >
                  <div className="ico klip-logo-black"></div>
                </div>
                클립 만들기 및 연결하기
              </button>
            </section>
          </div>
        </article>
        <article className="desc-page">
          <section>
            <header>
              <div className="item step1"></div>
              <h1>카카오계정으로 클립 회원가입 진행</h1>
            </header>
            <div className="item step1-phone"></div>
          </section>
          <div className="divider">
            <div className="ico arrow-down"></div>
          </div>
          <section>
            <header>
              <div className="item step2"></div>
              <h1>정보제공요청 확인 후, 다음 버튼 클릭</h1>
            </header>
            <div className="item step2-phone"></div>
          </section>
          <div className="divider">
            <div className="ico arrow-down"></div>
          </div>
          <section>
            <header>
              <div className="item step3"></div>
              <h1>한경서비스로 돌아와 연결확인 버튼 클릭</h1>
            </header>
            <div className="item step3-phone"></div>
          </section>
        </article>
      </section>

      {/* <section className="fixed__container">
        <div className="klip__button" onClick={requestKlip}>
          <span>카톡으로 클립 연결하기</span>
          <div className="ico klip"></div>
        </div>
      </section> */}

      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <article className="terms-modal">
          <header>
            <h1>한경 시프트플랫폼 이용 약관</h1>
            <div
              className="ico black_close"
              onClick={() => {
                closeModal();
              }}
            ></div>
          </header>
          <section className="terms-text">
            <h1>제1장 총칙</h1>
            <h2>제1조 [목적]</h2>
            <p>
              본 약관은 주식회사 한국경제신문(이하 “신문사”라 합니다)과 그룹사가 제공하는 한경 블록체인 서비스 플랫폼인
              「한경 블록체인 시프트 플랫폼」을 이용하는 회원 사이의 서비스 이용 및 제공에 관한 사항을 정함을 목적으로
              합니다.
            </p>
            <h2>제2조 [용어의 정의]</h2>
            <p>
              ① 본 약관에서는 용어를 다음과 같이 정의하여 사용합니다.<br></br>
              1. “그룹사”란 “신문사” 및 “신문사” 계열사인 주식회사 한국경제티브이(이하 “TV”라 합니다), 주식회사
              한경닷컴(이하 “닷컴”이라 합니다), 한국경제매거진 주식회사(이하 “매거진”이라 합니다), 주식회사
              한경비피(이하 “BP”라 합니다), 주식회사 한경엘앤디(이하 “엘앤디”라 합니다), 주식회사 이구필름, 주식회사
              한경필하모닉오케스트라(이하 “한경 필”이라 합니다), 주식회사 한경글로벌뉴스네트워크(이하 “글로벌”이라
              합니다), 코리아엔터테인먼트미디어 주식회사(이하 “코리아엔터”라 합니다), 주식회사 아그로플러스(이하
              “아그로”라 합니다)를 아울러 의미합니다.
              <br></br>2.“플랫폼”이란 각 “그룹사”가 회원 정보를 단순 정보전달 방식으로 제공하는 PC 및 모바일 서비스인
              「한경 블록체인 시프트 플랫폼」을 의미합니다.<br></br> 3. “회원”이란 “그룹사”가 제공하는 인터넷 관련
              서비스 이용약관과 본 약관에 따라 이용계약을 체결하고, “플랫폼”을 이용하는 자를 의미합니다.<br></br> 4.
              “서비스”란 “회원”의 단말기(모바일, 태블릿PC 등 각종 유무선 장치를 포함)를 통하여 “그룹사”가 제공하는 용역
              일체를 말합니다.<br></br> 5. “본인인증”이란 그룹사 “회원”의 본인 여부를 확인하기 위한 인증절차로써
              그룹사의 서비스에 가입한 전자메일주소(E-MAIL Address)와 그라운드원의 클립주소(KLIP Address)를 확인하는
              절차를 의미합니다.<br></br> 6. “KLIP”이란 그라운드원이 제공하는 디지털 지갑 서비스를 의미합니다.<br></br>
              7. “KLAY”란 “플랫폼”에서 사용되는 디지털자산을 의미합니다.<br></br> 8. “리워드”(Reward)란 “회원”이
              “그룹사”가 정하는 일정한 행위를 마친 때에 그룹사의 “리워드” 지급 기준에 따라 “KLAY”를 지급받는 것을
              의미합니다.<br></br>
              <br></br> ② 본 약관에서 사용하는 용어 중 본 조에서 정하지 아니한 것은 “플랫폼” 및 “서비스” 페이지 안내 및
              관계법령에서 정하는 바에 따르며, 그 외에는 일반 상관례에 따릅니다.
            </p>
            <h2>제3조 [약관의 효력 및 개정]</h2>
            <p>
              ① “그룹사”는 본 약관의 내용을 “회원”이 쉽게 알 수 있도록 “서비스” 상에 게시합니다.
              <br></br>② “그룹사”는 필요에 따라 약관의 규제에 관한 법률, 정보통신망 이용촉진 및 정보보호 등에 관한 법률
              등 관계법령을 위반하지 않는 범위 내에서 본 약관을 개정할 수 있습니다.
              <br></br> ③ “그룹사”가 약관을 개정할 경우에는 적용 예정일 및 개정사유를 명시하여 “서비스” 화면에 그 적용
              예정일 7일 전부터 공지하며, “회원”에게 불리하게 약관 내용을 변경하는 경우에는 최소한 30일 이상의 사전
              유예기간을 두고 공지합니다. 다만, “서비스”에 대한 새로운 기능과 관련된 변경이나 법률적인 사유로 인한
              변경은 사전 공지 없이 즉시 발효될 수 있습니다.
              <br></br> ④ “회원”은 변경된 약관에 동의하지 않을 경우 “회원” 해지를 요청할 수 있으며, 변경된 약관의 효력
              발생일 이후 7일(다만 “회원”에게 불리한 내용의 변경일 경우 30일) 안에 해지의 의사표시를 하지 않고 이후에도
              “서비스”를 계속 사용할 경우 약관의 변경 사항에 동의한 것으로 간주됩니다.
            </p>

            <h2>제4조 [약관의 해석]</h2>
            <p>
              ① 본 약관에서 정하지 않은 사항이나 해석에 대하여는 관계법령 또는 상관례에 따릅니다.
              <br></br>② “그룹사”는 “플랫폼” 내부의 개별 “서비스”에 대해 별도의 이용약관 또는 정책을 둘 수 있으며, 그
              내용이 본 약관과 충돌하는 경우 별도의 약관이 우선하여 적용됩니다.
              <br></br>③ “회원”은 항상 개별약관이나 이용정책의 내용에 변경이 있는지 여부를 주시하여야 하며, 변경사항의
              공지가 있을 시에는 이를 확인하여야 합니다.
            </p>
            <h1>제2장 이용계약의 체결</h1>
            <h2>제5조 [이용계약의 체결 및 적용]</h2>
            <p>
              ① 이용계약은 “회원”이 되고자 하는 자가 본 약관의 내용에 대하여 동의를 한 다음 “서비스” 이용 신청을 하고,
              “그룹사”가 그 신청에 대해서 승낙함으로써 체결됩니다. 필요한 경우 “그룹사”는 본인 확인 기관을 통한 실명확인
              및 본인인증을 요청할 수 있습니다. 다만, 이용신청자가 만 12세 미만일 경우 “회원” 가입 및 이용신청을 할 수
              없으며, 그 외 제한능력자인 경우는 법정대리인의 동의를 받아 이용신청을 할 수 있습니다.
              <br></br>② “서비스” 상 한 개인이 개설할 수 있는 최대한의 계정수는 1개로 제한됩니다.
              <br></br>③ “그룹사”는 이용신청자의 신청에 대하여 승낙함을 원칙으로 합니다. 다만, “그룹사”는 다음 각 호의
              어느 하나에 해당하는 이용 신청에 대해서는 승낙을 거절할 수 있습니다.
              <br></br>
              1. 이용신청자가 허위의 명의 또는 타인의 명의를 이용한 경우
              <br></br>
              2. “그룹사”가 “서비스”를 제공하지 않은 국가에서 비정상적이거나 우회적인 방법을 통해 “서비스”를 이용하는
              경우
              <br></br>
              3. 관계법령에서 금지하는 행위를 할 목적으로 신청하는 경우
              <br></br>
              4. 사회의 안녕과 질서 또는 미풍양속을 저해할 목적으로 신청한 경우
              <br></br>
              5. 부정한 용도로 “서비스”를 이용하고자 하는 경우
              <br></br>
              6. 이용신청자가 본 약관에 의하여 이전에 “회원” 자격을 상실한 적이 있는 경우. 다만, “회원” 자격 상실 후
              1년이 경과한 자로서 회사의 “회원” 재가입 승낙을 얻은 경우에는 예외로 합니다.
              <br></br>
              7. 그 밖에 각 호에 준하는 사유로서 승낙이 부적절하다고 판단되는 경우
              <br></br>④ “그룹사”는 다음 각 호의 어느 하나에 해당하는 경우 그 사유가 해소될 때까지 승낙을 유보할 수
              있습니다.
              <br></br>
              1. “그룹사”의 설비에 여유가 없거나, 특정 모바일 기기의 지원이 어렵거나, 기술적 장애가 있는 경우
              <br></br>
              2. “서비스” 상의 장애 또는 이용요금 결제수단의 장애가 발생한 경우
              <br></br>
              3. 그 밖의 각 호에 준하는 사유로서 이용신청의 승낙이 어렵다고 판단되는 경우
            </p>
            <h2>제6조 [“회원” 정보의 변경]</h2>
            <p>
              “회원”은 “그룹사”에 대한 “회원” 가입 및 “서비스” 이용 신청 시에 기재한 “회원” 정보에 변동이 생긴 경우 이를
              수정하거나 “그룹사”에 통지하여야 하며 미수정 또는 통지하지 않음으로 인한 불이익에 대하여 “그룹사”는
              책임지지 않습니다.
            </p>
            <h2>제7조 [“회원”에 대한 통지]</h2>
            <p>
              ① “그룹사”가 “회원”에 대한 통지를 하는 경우 “회원”이 지정한 이메일로 할 수 있습니다.
              <br></br>② “그룹사”는 “회원” 전체에 대한 통지의 경우 7일 이상(다만 “회원”에게 불리한 내용의 통지일 경우
              30일) “그룹사”의 공지사항에 게시함으로써 제1항의 통지에 갈음할 수 있습니다. 다만, “회원” 본인의 거래와
              관련하여 중대한 영향을 미치는 사항에 대하여는 제1항의 통지를 합니다.
            </p>
            <h1>제3장 계약 해제·해지 및 이용제한</h1>
            <h2>제8조 [“회원”의 계약해지 및 자격상실 등]</h2>
            <p>
              ① “회원”은 언제든지 “서비스” 내 계정관리 메뉴(마이 월렛)를 통하여 “서비스” 이용계약 해지를 신청할 수
              있으며 “그룹사”는 이를 즉시 처리합니다.
              <br></br>② 위의 해지 시 “플랫폼”에 제공된 개인정보는 “개인정보처리방침”에 따라 파기됩니다.
              <br></br>③ 해지로 인하여 “서비스” 이용 정보가 삭제됨에 따라 “회원”에게 발생하는 손해에 대해 “그룹사”는
              책임지지 않습니다.
              <br></br>④ 지급 예정 “리워드”는 해지와 동시에 소멸됩니다.
              <br></br>⑤ “그룹사”는 다음의 경우 사전 통보 없이 해당 “회원”과의 이용계약을 해지하여 “회원” 자격을
              상실시킬 수 있습니다. <br></br>
              <br></br>
              1. “회원”이 사망한 경우 <br></br>
              2. 타인의 개인정보 또는 모바일 기기를 도용한 경우 <br></br>
              3. “회원”이 제출한 개인정보가 허위임이 밝혀진 경우 <br></br>
              4. “서비스” 외에서 “그룹사”의 명예를 손상시키거나 “그룹사”의 임직원 또는 “그룹사”를 사칭, 업무를 방해하는
              경우 <br></br>
              5. “서비스”에 관한 스팸성 홍보 활동을 하는 경우 <br></br>
              6. 제16조 제3항 각 호 및 기타 본 약관 상의 의무를 위반하거나 또는 법령에 위배되는 행위를 한 경우 <br></br>
              7. 위 각 호에 준하는 경우 <br></br>
              <br></br>⑥ 위 제5항의 사유로 자격상실 통보를 받은 “회원”의 자격은 즉시 상실됩니다.
              <br></br>⑦ “그룹사”는 위의 사유가 있는 경우 자격상실 대신 제9조에 따른 이용제한을 가할 수 있습니다.
            </p>
            <h2>제9조 [이용제한 등]</h2>
            <p>
              ① 본 조 및 제8조 제7항에 따라 “서비스” 이용을 제한하는 경우에는 “그룹사”는 제7조 [“회원”에 대한 통지]에
              따라 통지를 하고 “회원”은 30일 내에 소명의 기회를 부여 받습니다.
              <br></br>② “회원”은 본 조에 따른 이용제한 등에 대해 유선 연락, 이메일 등 유효한 방법에 따라 이의신청을 할
              수 있습니다. 이때 이의가 정당하다고 “그룹사”가 인정하는 경우 “그룹사”는 즉시 “서비스”의 이용을 재개합니다.
              <br></br>③ “회원”이 “서비스”를 이용하는 도중 연속하여 1년 동안 “그룹사”의 “서비스”에 로그인한 기록이 없는
              경우(이하 “휴면 회원”이라 합니다) “그룹사”는 “회원”과의 “서비스” 이용계약을 해지할 수 있습니다.
              <br></br>④ “그룹사”는 “휴면 회원”에게 이메일로 “서비스” 계약해지에 관한 사항에 대해 사전 통지하며,
              계약해지의 효력은 이메일에서 안내한 기간이 경과한 때에 발생합니다.
            </p>
            <h1>제4장 운영정책</h1>
            <h2>제 10조 [운영정책]</h2>
            <p>
              ① “그룹사”는 공정한 “서비스”의 유지 및 “회원”의 보호 등을 위해 별도의 운영정책을 운영할 수 있으며,
              “회원”은 “그룹사”가 정한 운영정책을 반드시 확인하고 준수하여야 합니다.
              <br></br>② 본 약관에서 정하지 않은 사항이나 해석에 대해서는 운영정책, 이용정책, 관계법령에 따릅니다.
              <br></br>③ “회원”은 “그룹사”가 고지한 운영정책에 위반되는 행위를 할 경우 본 약관 및 운영정책에 따라
              이용계약을 해지할 수 있습니다.
            </p>
            <h1>제5장 서비스 관련 사항</h1>
            <h2>제11조 [“리워드”]</h2>
            <p>
              ① “리워드”는 “회원”이 “서비스”에서 제공하는 이벤트에 참여하거나 동영상 및 광고를 시청하는 등 “그룹사”가
              정한 “리워드” 제공 기준 및 취지에 맞게 일정한 행위를 완료하면 지급됩니다.
              <br></br>② 지급 내역은 “서비스” 내 마이월렛 정보 화면에서 확인하실 수 있습니다.
              <br></br>③ 서버에 입력된 “리워드” 액수와 클라이언트 상의 “리워드” 액수 간에 차이가 나는 경우 항상 서버의
              수치를 기준으로 하며, 서버와 클라이언트의 비동기화로 인한 리워드액 차이는 정정이 불가능 합니다.
              <br></br>④ “리워드”와 관련하여 발생하는 제세공과금은 “회원”의 부담으로 합니다.
            </p>
            <h2>제12조 [“리워드” 사용]</h2>
            <p>
              ① “회원”은 “리워드”로 “그룹사”의 다양한 상품, 콘텐츠, 서비스 등(이하 “상품 등”)을 구매할 수 있습니다.
              <br></br>② “상품 등”의 판매가는 거래소 등 디지털자산 시세를 제공하는 업체의 정보를 바탕으로 산정되므로
              기존 판매가와 다를 수 있습니다. 상세 내용은 “상품 등”의 상세 설명에서 확인하실 수 있습니다.
              <br></br>③ “회원”은 구매한 “상품 등”을 환불할 수 있으며, 환불 조건⋅환불 기한⋅환불 방법 등 환불에 관한 상세
              내용은 “상품 등”의 상세설명에서 확인하실 수 있습니다. “상품 등”이 택배로 발송되어 배송비가 있는 경우
              환불시 구매금액에서 왕복 배송비가 차감될 수 있습니다.
            </p>
            <h2>제13조 [“리워드”의 정정, 취소 및 환급]</h2>
            <p>
              ① “리워드”에 오류가 발생한 경우 “회원”은 오류 발생일로부터 90일 이내에 “그룹사”에 정정요구를 할 수 있으며,
              “그룹사”는 정당한 요구임이 확인된 경우 정정요구일로부터 90일 이내에 정정하여야 합니다.
              <br></br>② 클라이언트 변경, 해킹, 매크로, 1인 1계정 위반 등 “그룹사”가 허용하지 않는 부정한 방법으로
              “리워드”를 수령한 경우, 부정 수령이 되어 그 수령금은 “회원”이 “그룹사”로 환급해야 합니다.
            </p>
            <h1>제6장 계약 당사자의 의무</h1>
            <h2>제15조 [“그룹사”의 의무]</h2>
            <p>
              ① “그룹사”는 관계법령 또는 본 약관을 위반하지 않으며, 계속적이고 안정적으로 “서비스”를 제공하기 위하여
              최선을 다하여 노력합니다.
              <br></br>② “그룹사”는 신용정보를 포함한 “회원”의 개인정보 보호를 위하여 보안시스템을 갖추어야 하며
              “개인정보처리방침”을 공시하고 준수하겠습니다.
              <br></br>③ “그룹사”는 “서비스” 이용과 관련하여 “회원”으로부터 제기된 의견이나 불만이 정당하다고 인정할
              경우에는 이를 처리할 수 있도록 최선을 다하여 노력합니다.
            </p>
            <h2>제16조 [“회원”의 의무]</h2>
            <p>
              ① “회원”은 “그룹사” “서비스” 이용 신청 시 기재한 사항이 변경되었거나 잘못 입력한 경우 즉시 그 정보를
              변경하여야 하며, 변경사항을 적용하지 않아 발생한 불이익에 대해서는 “그룹사”가 책임지지 않습니다.
              <br></br>② “회원”은 부정(불법)한 방법 혹은 “그룹사”가 정한 약관, 운영정책 및 준칙에 어긋나는 방향,
              “서비스” 제공 목적 이외의 용도로 “서비스”를 이용할 수 없습니다. 이를 위반 시 이에 대한 모든 책임 (손해배상
              포함)은 해당 “회원”에게 있으며 “그룹사”는 “회원”에 대한 사전 통지 없이 “회원”의 “회원” 자격을 제한, 정지
              및 해지할 수 있고, 이 경우 “회원”은 “서비스”와 관련된 모든 권리를 주장할 수 없습니다.
              <br></br>③ “회원”은 “서비스” 이용과 관련하여 다음의 행위를 하여서는 안됩니다.<br></br>
              <br></br>
              1. “서비스” 이용 관련 제반 신청 및 변경 행위 시 허위내용의 등록하는 행위<br></br>
              2. 다른 “회원”의 개인정보(이메일, 휴대전화번호 등)를 도용하는 행위<br></br>
              3. 콘텐츠(기사 및 영상 등)의 부정 사용 및 부정 사용 유도를 통해 부당하게 “리워드”를 취득하는 행위 및 그 외
              기타 사회 통념상 정상적이지 않은 방법으로 “리워드”를 수령하는 행위<br></br>
              4. 자동 프로그램 등을 사용하는 등 정상적인 방법과 다른 방법으로 “서비스”를 이용하여 “그룹사”의 정상적인
              “서비스”를 방해하는 행위<br></br>
              5. 무단으로 “그룹사”의 클라이언트 프로그램을 변경하거나 서버를 해킹하는 등 “그룹사” 및 “서비스”의 시스템을
              위협하는 행위<br></br>
              6. 다른 “회원”의 “서비스” 이용을 방해하는 등 전자거래질서를 위협하는 행위<br></br>
              7. “그룹사” 및 제3자의 특허권, 상표권, 저작권, 영업비밀 등 지식재산권을 포함한 기타 권리를 침해하거나
              침해하는 방법으로 사용하는 행위<br></br>
              8. “그룹사”의 명예를 손상시키거나 “그룹사”의 임직원 또는 “그룹사”를 사칭, 업무를 방해하는 행위<br></br>
              9. “그룹사”의 명시적인 동의 없이 콘텐츠를 전송하거나 외설, 폭력적인 정보 등을 노출하는 행위<br></br>
              10. 기타 약관상의 의무를 불이행하는 행위 또는 불법, 부당한 행위
              <br></br>
              <br></br>④ “회원”은 자신의 “그룹사” 가입 계정 및 비밀번호 등의 관리 의무가 있으며, “회원”의 과실에 따라
              “그룹사” 가입 계정 및 비밀번호 분실, 도난, 유출 등으로 발생하는 모든 불이익에 대한 책임을 부담합니다.
              <br></br>⑤ “그룹사”는 “회원”의 최소한의 개인정보만 수집하므로 “회원”이 “그룹사” 가입 계정을 분실한 경우
              이를 “회원”의 다른 개인정보와 대조하여 찾는 것이 불가능합니다. 따라서 “그룹사” 가입 계정 분실에 대한
              책임은 전적으로 “회원”에게 있습니다.
              <br></br>⑥ “회원”은 “그룹사” 가입 계정 및 비밀번호 등을 도난당하거나 제3자가 사용하고 있음을 인지한
              경우에는 즉시 본인의 비밀번호를 수정하는 등의 조치를 취하여야 하며 계속해서 문제가 있을 경우 이를
              “그룹사”에 통보하여 “그룹사”의 안내를 따라야 합니다.
              <br></br>⑦ “회원”은 관계법령, 본 약관의 규정 및 “그룹사”가 통지하는 사항 등을 준수하여야 하며, 미준수 시
              이에 대한 모든 책임은 해당 “회원”에게 있습니다.
            </p>
            <h2>제17조 [“서비스”의 변경 등]</h2>
            <p>
              ① “그룹사”는 “서비스”의 운영과 관련한 공지사항을 “서비스” 공지사항에 게재하여 통지할 수 있습니다.
              <br></br>② “그룹사”는 수시로 “서비스”의 향상을 위하여 기존 “서비스”의 전부 또는 일부를 변경할 수 있으며,
              기존 “회원”의 권리를 제한하는 변경의 경우 30일 전에 사전 통지를 하고 “회원”에게 이용계약을 해지할 수 있는
              기회를 부여합니다.
              <br></br>③ “그룹사”는 다음 각 호에 해당하는 경우 “서비스”의 전부 또는 일부를 제한하거나 중지할 수
              있습니다.<br></br>
              <br></br>
              1. 이용 감소로 인한 원활한 “서비스” 제공의 곤란 및 수익성 악화, 기타 “그룹사”의 경영상 판단에 의한 경우
              <br></br>
              2. 기술 진보에 따른 차세대 “서비스”로의 전환 필요성, “서비스” 제공과 관련한 “그룹사” 정책의 변경의 경우
              <br></br>
              3. 컴퓨터 등 정보통신설비의 보수점검 교체 및 고장, 통신의 두절의 경우<br></br>
              4. “서비스”를 위한 설비의 보수 등 공사로 인해 부득이한 경우<br></br>
              5. “서비스” 업그레이드를 위해 필요한 경우<br></br>
              6. 정전, 제반 설비의 장애 또는 트래픽 폭주 등으로 정상적으로 이용이 불가능한 경우<br></br>
              7. 기타 천재지변, 국가 비상사태 등 불가항력적 사유에 의한 경우<br></br>
              <br></br>④ 본 조 제2항에 의한 “서비스” 제한 또는 중지의 경우 제7조 [“회원”에 대한 통지]에 따라 “회원”에게
              통지합니다. 다만, “그룹사”가 통제할 수 없는 사유로 인하여 사전 통지가 불가능한 경우에는 사후에 통지를 할
              수 있습니다. 변경 등의 사유 또는 내용을 구체적으로 공지하기 어려운 경우에는 그 이유를 밝힙니다.
              <br></br>⑤ “그룹사”는 고의 또는 중과실로 인한 귀책사유가 없는 한 본 조 제2항의 사유로 “서비스”의 제공이
              제한 또는 중지됨으로 인하여 “회원” 또는 제3자가 입은 손해에 대해 배상하지 않음을 원칙으로 합니다.
            </p>
            <h2>제18조 [“서비스”의 종료]</h2>
            <p>
              ① “그룹사”는 “서비스”를 종료하고자 하는 날로부터 3개월 이전에 본 약관 제3조 제3항의 방법으로 “회원”에게
              알립니다.
              <br></br>② 종료 통지일로부터 “서비스” 종료일까지 “서비스”의 일부가 제한될 수 있습니다.
            </p>
            <h2>제19조 [마케팅 메시지 제공]</h2>
            <p>
              ① “그룹사”는 “서비스”의 운영과 관련한 각종 정보 및 광고를 “서비스” 화면에 게재하는 등의 방법으로
              “회원”에게 제공할 수 있습니다.
              <br></br>② “그룹사”는 “회원” 전체 또는 일부의 개인정보를 일부 삭제하거나 일부 또는 전부를 대체하는 등의
              방법으로 추가 정보의 사용⋅결합 없이는 원래 상태로 복원할 수 없고 특정 “회원”을 알아볼 수 없도록 처리한 뒤,
              “서비스”의 개선 및 고객만족도 향상 등을 위한 통계작성 등의 목적으로 사용할 수 있습니다.
            </p>
            <h2>제20조 [저작권 등의 귀속]</h2>
            <p>
              ① “그룹사”가 작성한 저작물에 대한 저작권 및 기타 지식재산권은 “그룹사”에 귀속합니다.
              <br></br>② “그룹사”가 제공하는 “서비스” 중 제휴계약에 의해 제공되는 저작물에 대한 저작권 및 기타
              지식재산권은 해당 제공업체에 귀속합니다.
              <br></br>③ 위 제2항 외 “그룹사”가 제공하는 “서비스”의 영상, 디자인, “그룹사”가 만든 텍스트, 스크립트,
              그래픽 등 “그룹사”가 제공하는 “서비스”에 관련된 모든 상표, “서비스” 마크, 로고 등에 관한 저작권, 기타
              지식재산권은 “그룹사” 또는 “그룹사”를 구성하는 “신문사” 및 각 계열사에 있습니다.
              <br></br>④ “회원”은 “그룹사”가 제공하는 “서비스”를 이용함으로써 얻은 정보 중 “그룹사” 또는 제공업체에
              지식재산권이 귀속된 정보를 “그룹사” 또는 제공업체의 사전승낙 없이 복제, 전송, 출판, 배포, 방송 및 기타
              방법에 의하여 영리목적으로 이용하거나 제3자에게 이용하게 하여서는 안 됩니다.
              <br></br>⑤ “회원”은 본 약관으로 인하여 “서비스”를 소유하거나 “서비스”에 관한 저작권을 보유하게 되는 것이
              아니라, “그룹사”로부터 “서비스”의 이용을 허락 받게 되는바, “회원”은 “서비스”를 정보취득 및 기타 “서비스”가
              개인용도로 제공하는 형태에 대해서만 이용할 수 있습니다.
            </p>
            <h2>제21조 [개인정보보호 의무]</h2>
            <p>
              ① “회원”의 개인정보는 해당 정보를 “플랫폼”에 전달⋅제공하는 “신문사” 및 그 계열사에서 보관⋅관리됩니다.
              “그룹사”는 관계법령이 정하는 바에 따라 “회원”의 개인정보를 보호하기 위해 노력하며, 개인정보의 보호 및
              사용에 대해서는 관계법령 및 “그룹사”의 “개인정보처리방침”이 적용됩니다.
              <br></br>② “그룹사”는 “서비스”를 중단하거나 “회원”이 개인정보 제공 동의를 철회한 경우에는 신속하게
              “회원”의 개인정보를 파기합니다. 다만, 전자상거래 등에서의 소비자 보호에 관한 법률 등 관계법령에서 정하는
              바에 따라 일정 정보는 일정 기간 동안 보관할 수 있습니다.
              <br></br>③ “그룹사”는 “서비스” 개선 및 “회원” 대상의 “서비스” 소개 등의 목적으로 “회원”의 동의 하에
              관계법령에서 정하는 바에 따라 추가적인 개인정보를 수집할 수 있습니다.
              <br></br>④ “그룹사”는 원활한 “서비스” 운영을 위해 “회원”의 정보를 “회원”이 동의를 하였거나 관계법령에서
              정해진 범위 내에서 제3자에게 제공할 수 있습니다. 이 경우, “그룹사”는 개인정보 보호법, 정보통신망 이용촉진
              및 정보보호 등에 관한 법률 등 관계법령에 따라 “회원”으로부터 동의를 받습니다.
            </p>
            <h1>제7장 손해배상 및 환불 등</h1>
            <h2>제22조 [손해배상]</h2>
            <p>
              “회원”이 약관 상의 의무를 위반하거나 이행하지 아니하여 “그룹사” 또는 제3자에게 손해를 입힌 경우는 그
              손해를 배상하여야 합니다.
            </p>
            <h2>제23조 [면책조항]</h2>
            <p>
              “그룹사”, “그룹사”의 임직원과 대리인은 다음 각 호의 사항 및 각 호의 사항으로부터 발생하는 손해에 대한
              일체의 책임이 면제되고, 이에 관하여 “회원” 및 제3자가 입은 어떠한 손해에 대해서도 배상책임을 지지
              아니합니다.
              <br></br>
              <br></br>
              1. 천재지변 또는 이에 준하는 불가항력으로 인하여 “서비스”를 제공하지 못하는 경우 및 이로 인하여 발생한
              손해<br></br>
              2. “회원”의 “서비스”에 대한 접속 및 이용과정에서 “회원”의 귀책사유로 인하여 발생한 “서비스” 이용의 장애 및
              이로 인하여 발생한 손해<br></br>
              3. “회원” 간 또는 “회원”과 제3자 상호 간에 “서비스”를 매개로 하여 이루어진 거래 및 이에 관하여 발생한 손해
              <br></br>
              4. 무료로 제공되는 “서비스”의 이용 시의 불편, 고장, 중단, 장애 및 이로 인하여 발생한 손해 중 “그룹사”의
              고의 또는 중과실로 인한 귀책사유가 없는 경우<br></br>
              5. “회원” 정보의 허위 또는 부정확성에 기인하는 손해<br></br>
              6. 제3자의 서버에 대한 불법적인 접속 또는 제3자의 서버에 대한 불법적인 이용 및 이로 인하여 발생하는 손해
              <br></br>
              7. 서버에 대한 전송 또는 서버로부터의 전송에 대한 제3자의 모든 불법적인 방해 또는 중단행위 및 이로 인하여
              발생한 손해<br></br>
              8. “회원” 간의 “서비스” 이용 과정에서 발생하는 명예훼손, 기타 불법 행위로 인한 손해 및 “회원” 간의 각종
              민⋅형사상 책임<br></br>
              9. 제16조 [“회원”의 의무]에 위배되는 행동으로 인해 발생한 그 어떠한 법적인 문제 및 이로 인하여 “회원” 또는
              제3자가 입은 손해<br></br>
              10. “그룹사”가 제공하는 “서비스”가 아닌 제3자가 제공한 “서비스”의 제공 상의 오류 및 이로 인하여 발생한
              손해<br></br>
            </p>
            <h2>제24조 [준거법 및 분쟁의 해결]</h2>
            <p>
              ① 본 약관과 본 약관에 따른 이용계약 및 이에 따라 발생하는 권리와 의무에 관해서는 대한민국법에 따라
              규율되고 해석됩니다.
              <br></br>② “그룹사”는 “서비스” 이용과 관련한 “회원”의 의견이나 불만사항을 신속하게 처리합니다. 다만,
              신속한 처리가 곤란한 경우에는 그 사유와 처리일정을 통보하여 드립니다.
              <br></br>③ “그룹사”와 “회원” 간에 발생한 분쟁이 발생한 경우 “회원”은 관계법령에 따른 조정절차를 신청하여
              위 절차에 의해 분쟁을 해결할 수 있습니다.
              <br></br>④ “그룹사”와 “회원” 간 발생한 분쟁에 관한 소송은 민사소송법에 따른 관할 법원에 제기할 수 있으며,
              대한민국의 법령에 따른 절차에 의하여 처리됩니다.
              <br></br>
              <br></br>
              부칙 <br></br>① 본 약관은 2021년 4월 1일 부터 적용됩니다.
            </p>
          </section>
        </article>
        <section></section>
      </Modal>
      <Modal
        isOpen={modalCollectionIsOpen}
        onAfterOpen={afterOpenModalCollection}
        onRequestClose={closeModalCollection}
        style={customStyles2}
        contentLabel="Example Modal"
      >
        <article className="terms-modal">
          <header>
            <h1>개인정보 수집 및 이용 동의</h1>
            <div
              className="ico black_close"
              onClick={() => {
                closeModalCollection();
              }}
            ></div>
          </header>
          <section className="terms-text">
            <p>
              한국경제미디어그룹은 고객님께 원활한 서비스 제공을 위하여 아래와 같이 개인정보를 수집 및 이용하고
              있습니다.
            </p>
            <h2>1. 수집 이용 목적</h2>
            <p>- 시프트플랫폼을 통해 가상자산(KLAY등) 지급 및 관리</p>
            <h2>2.수집하는개인정보 항목</h2>
            <p> -이메일주소</p>
            <h2>3.보유및 이용기간</h2>
            <p> -한경 통합회원 탈퇴시까지​</p>
            <br />
            <p>
              ※고객님은 개인정보 수집 및 이용 동의에 대한 동의를 거부할 권리가 있으나, 미 동의 시 시프트플랫폼 서비스
              이용에 제한이 있을 수 있습니다.
            </p>
          </section>
        </article>
      </Modal>
      <Modal
        isOpen={modalPrivacyIsOpen}
        onAfterOpen={afterOpenModalPrivacy}
        onRequestClose={closeModalPrivacy}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <article className="terms-modal">
          <header>
            <h1>한국경제신문 개인정보 처리방침</h1>
            <div
              className="ico black_close"
              onClick={() => {
                closeModalPrivacy();
              }}
            ></div>
          </header>
          <section className="terms-text">
            <p>
              ㈜한국경제신문(이하 “회사”)은 개인정보 보호법 제30조에 따라 정보주체의 개인정보를 보호하고 이와 관련한
              고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리지침을 수립․공개합니다.
            </p>
            <h2>제1조(개인정보의 처리목적)</h2>
            <p>
              회사는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는
              이용되지 않으며, 이용 목적이 변경되는 경우에는 개인정보 보호법 제18조에 따라 별도의 동의를 받는 등 필요한
              조치를 이행할 예정입니다.
            </p>
            <br></br>
            <p>
              ⦁한경 블록체인 시프트플랫폼(이하“플랫폼”) 서비스 이용 및 관리 서비스 이용 의사 확인, 서비스 제공에 따른
              본인 식별․인증, 자격 유지․관리, 제한적 본인 확인제 시행에 따른 본인확인,
            </p>

            <h2> 제2조(개인정보의 처리 및 보유기간)</h2>
            <p>
              1. 회사는 법령에 따른 개인정보 보유․이용기간 또는 정보주체로부터 개인정보를 수집시에 동의 받은 개인정보
              보유․이용기간 내에서 개인정보를 처리․보유합니다.
              <br />
              2. 각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.
              <br />
              ⦁한경 통합회원 가입 동안 개인정보를 위탁합니다.
            </p>
            <h2>제3조(개인정보처리의 위탁) </h2>
            <p>
              1. 회사는 원활한 개인정보 업무처리를 위하여 다음과 같이 개인정보 처리업무를 위탁하고 있습니다. <br />
              ⦁시프트플랫폼 개발 및 관리 <br />
              - 위탁받는 자 (수탁자) : ㈜코어다트랩 <br />
              - 위탁하는 업무의 내용 : 플랫폼 개발 및 서비스 운영과 관리 <br />
              <br />
              2. 회사는 위탁계약 체결시 개인정보 보호법 제26조에 따라 위탁업무 수행목적 외 개인정보 처리금지,
              기술적․관리적 보호조치, 재위탁 제한, 수탁자에 대한 관리․감독, 손해배상 등 책임에 관한 사항을 계약서 등
              문서에 명시하고, 수탁자가 개인정보를 안전하게 처리하는지를 감독하고 있습니다.
              <br />
              3. 위탁업무의 내용이나 수탁자가 변경될 경우에는 지체 없이 본 개인정보 처리방침을 통하여 공개하도록
              하겠습니다.
            </p>
            <h2>제4조(정보주체의 권리․의무 및 행사방법) </h2>
            <p>
              1. 정보주체는 회사에 대해 언제든지 다음 각 호의 개인정보 보호 관련 권리를 행사할 수 있습니다. <br />
              1)개인정보 열람요구 <br />
              <br />
              2)오류 등이 있을 경우 정정 요구 <br />
              3)삭제요구 <br />
              4)처리정지 요구 <br />
              <br />
              2. 제1항에 따른 권리 행사는 회사에 대해 서면, 전화, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며
              회사는 이에 대해 지체없이 조치하겠습니다.
              <br />
              <br />
              3. 정보주체가 개인정보의 오류 등에 대한 정정 또는 삭제를 요구한 경우에는 회사는 정정 또는 삭제를 완료할
              때까지 당해 개인정보를 이용하거나 제공하지 않습니다.
              <br />
              <br />
              4. 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다.
              이 경우 개인정보 처리 방법에 관한 고시 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.
              <br />
              <br />
              5. 정보주체는 개인정보 보호법 등 관계법령을 위반하여 회사가 처리하고 있는 정보주체나 타인의 개인정보 및
              사생활을 침해하여서는 아니됩니다.
            </p>
            <h2>제5조(처리하는 개인정보 항목) </h2>
            <p>
              회사는 다음의 개인정보 항목을 처리하고 있습니다. <br />
              ⦁서비스 이용과 플랫폼 개발 및 관리 <br />
              ․필수항목 : 이메일주소
            </p>
            <h2> 제6조(개인정보의 파기)</h2>
            <p>
              1. 회사는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당
              개인정보를 파기합니다.
              <br />
              <br />
              2. 정보주체로부터 동의받은 개인정보 보유기간이 경과하거나 처리목적이 달성되었음에도 불구하고 다른 법령에
              따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나
              보관장소를 달리하여 보존합니다.
              <br />
              <br />
              3. 개인정보 파기의 절차 및 방법은 다음과 같습니다. <br />
              <br />
              1)파기절차 <br />
              회사는 파기 사유가 발생한 개인정보를 선정하고, 회사의 개인정보 보호책임자의 승인을 받아 개인정보를
              파기합니다.
              <br />
              2)파기방법 <br />
              회사는 전자적 파일 형태로 기록․저장된 개인정보는 기록을 재생할 수 없도록 로우레밸포멧(Low Level Format)
              등의 방법을 이용하여 파기하며, 종이 문서에 기록․저장된 개인정보는 분쇄기로 분쇄하거나 소각하여 파기합니다.
            </p>
            <h2>제7조(개인정보의 안전성 확보조치) </h2>
            <p>
              회사는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다. <br />
              <br />
              1. 관리적 조치: 개인정보 보호책임자의 지정 등 개인정보 보호 조직의 구성 및 운영에 관한 사항 등을 포함하여
              개인정 보 내부관리계획을 수립하고, 매 년 내부관리계획을 잘 시행하고 있는지를 점검하고 있습니다.
              <br />
              <br />
              2. 기술적 조치 : 개인정보에 대한 불법적인 접근을 차단하기 위해 개인정보 처리시스템에 대한 접근 권한의 부여
              ㆍ변경 ㆍ말소 등에 관한 기준을 수립하여 시행하고 있으며, 침입차단시스템 및 침입탐지시스템을 설치ㆍ운영하고
              있습니다.
              <br />
              <br />
              3. 물리적 조치 : 해킹이나 컴퓨터 바이러스 등에 의해 회원의 개인정보가 유출되거나 훼손되는 것을 막기 위해
              외부로부터 접근이 통제된 구역에 시스템을 설치하고 있으며, 출입통제 절차를 수립ㆍ운영하고 있습니다.
            </p>
            <h2>제8조(개인정보 자동 수집 장치의 설치∙운영 및 거부에 관한 사항) </h2>
            <p>
              1. 회사는 이용자에게 개별적인 맞춤서비스를 제공하기 위해 이용정보를 저장하고 수시로 불러오는
              ‘쿠키(cookie)’를 사용할 수 있습니다. <br />
              <br />
              2. 쿠키는 웹사이트를 운영하는데 이용되는 서버(http)가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며
              이용자들의 PC 컴퓨터내의 하드디스크에 저장되기도 합니다. <br />
              <br />
              1)쿠키의 사용목적: 이용자가 방문한 각 서비스와 웹 사이트들에 대한 방문 및 이 용형태, 인기 검색어, 보안접속
              여부, 등을 파악하여 이용자에게 최적화된 정보 제공을 위해 사용됩니다. <br /> <br />
              2)쿠키의 설치∙운영 및 거부 : 웹브라우저 상단의 도구>인터넷 옵션>개인정보 메 뉴의 옵션 설정을 통해 쿠키
              저장을 거부 할 수 있습니다. <br /> <br />
              3)쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수 있습니다.
              <br></br>
            </p>
            <h2>제9조(개인정보 보호책임자)</h2>
            <p>
              1. 회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및
              피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.
            </p>
            <div style={{ marginTop: 10, marginBottom: 10 }}>
              <table>
                <thead>
                  <tr>
                    <th>1.개인정보 보호책임자</th>
                    <th>2.개인정보 보호담당자</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td> ⦁이름: 김수언</td>
                    <td>⦁이름: 홍재명</td>
                  </tr>
                  <tr>
                    <td> ⦁소속: 기획조정실</td>
                    <td> ⦁소속: 기획조정실</td>
                  </tr>
                  <tr>
                    <td> ⦁직위: 실장</td>
                    <td> ⦁직위: 팀장</td>
                  </tr>
                  <tr>
                    <td>⦁전화번호: 02-360-4114</td>
                    <td>⦁전화번호: 02-360-4114</td>
                  </tr>
                  <tr>
                    <td>⦁메일주소: privacy@hankyung.com</td>
                    <td>⦁메일주소: privacy@hankyung.com</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p>
              2. 정보주체께서는 회사의 서비스(또는 사업)을 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리,
              피해구제 등에 관한 사항을 개인정보 보호책임자 및 담당부서로 문의하실 수 있습니다. 회사는 정보주체의 문의에
              대해 지체없이 답변 및 처리해드릴 것입니다.
            </p>
            <h2>제10조(권익침해 구제방법) </h2>
            <p>
              정보주체는 아래의 기관에 대해 개인정보 침해에 대한 피해구제, 상담 등을 문의하실 수 있습니다. <br></br>
              <br></br>
              아래의 기관은 회사와는 별개의 기관으로서, 회사의 자체적인 개인정보 불만처리, 피해구제 결과에 만족하지
              못하시거나 보다 자세한 도움이 필요하시면 문의하여 주시기 바랍니다<br></br>
              <br></br>
              ●개인정보 침해신고센터 (한국인터넷진흥원 운영) <br></br>- 소관업무: 개인정보 침해사실 신고, 상담 신청{' '}
              <br></br>- 홈페이지: privacy.kisa.or.kr <br></br>- 전화: (국번없이) 118 <br></br>- 주소: (58324) 전남
              나주시 진흥길 9(빛가람동 301-2) 3층 개인정보침해신고센터<br></br>
              <br></br>● 개인정보 분쟁조정위원회<br></br>- 소관업무 : 개인정보 분쟁조정신청, 집단분쟁조정 (민사적 해결){' '}
              <br></br>- 홈페이지 : www.kopico.go.kr <br></br>- 전화 : (국번없이) 1833-6972<br></br>- 주소 :
              (03171)서울특별시 종로구 세종대로 209 정부서울청사 12층
              <br></br>
              <br></br>
              ●대검찰청 과학수사부 사이버수사과: (국번없이) 1301(www.spo.go.kr)<br></br>
              <br></br>● 경찰청 사이버국 : 182 (http://cyberbureau.police.go.kr)<br></br>
              <br></br>
            </p>
            <h2> 제11조(개인정보 처리방침 변경)</h2>
            <p>
              이 개인정보 처리방침은 2021. 4. 5부터 적용됩니다.<br></br>
              ⦁공고일자 : 2021년 04월 05일<br></br>
              ⦁시행일자 : 2021년 04월 05일
            </p>
          </section>
        </article>
        <section></section>
      </Modal>
    </article>
  );
};
