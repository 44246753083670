import React, { useContext } from 'react';
import { AlertContext } from '../contexts';

export const AlertLayer = (props) => {
  const { children } = props;
  const { alert } = useContext(AlertContext);

  let layerClassName = 'alert-layer';
  if (!alert.open) {
    layerClassName += ' hide';
  }

  return (
    <>
      <div className={layerClassName}>Alert</div>
      {children}
    </>
  );
};
