import React from 'react';
import { BrowserRouter, Switch, Route, Link } from 'react-router-dom';
import { MainLayout, WalletLayout, Auth2Layout, RewardLayout, DefaultLayout } from './layouts';
import {
  AuthPage,
  Auth2Page,
  ResultPage,
  WalletPage,
  RetryPage,
  RewardPage,
  ErrorPage,
  QrResultPage,
  PayPage,
  PayResultPage,
  Auth3Page,
  FixPage,
} from './pages';

export const Router = () => {
  return (
    <BrowserRouter>
      <Switch>
        <DefaultLayout path="/auth" Component={AuthPage}></DefaultLayout>
        <DefaultLayout path="/maintenance" Component={FixPage}></DefaultLayout>
        {/* <Auth2Layout path="/auth2" Component={Auth2Page}></Auth2Layout>
        <Auth2Layout path="/auth3" Component={Auth3Page}></Auth2Layout> */}
        <WalletLayout path="/wallet" Component={WalletPage}></WalletLayout>
        <RewardLayout path="/reward" Component={RewardPage}></RewardLayout>
        <DefaultLayout path="/result" Component={ResultPage}></DefaultLayout>
        <DefaultLayout path="/qr_result" Component={QrResultPage}></DefaultLayout>
        <DefaultLayout path="/retry" Component={RetryPage}></DefaultLayout>
        <DefaultLayout path="/error" Component={ErrorPage}></DefaultLayout>
        <MainLayout title="결제" path="/pay" Component={PayPage}></MainLayout>
        <DefaultLayout path="/pay_result" Component={PayResultPage}></DefaultLayout>
      </Switch>
    </BrowserRouter>
  );
};
