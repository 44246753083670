import React, { useEffect } from 'react';
import queryString from 'query-string';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Base64 } from 'js-base64';
import Utils from '../utils';

export const FixPage = (props) => {
  const location = useLocation();
  const queryObject = queryString.parse(location.search);
  console.log('queryObject', queryObject);

  const message = decodeURIComponent(queryObject.message);
  console.log('message', message);

  return (
    <article className="fix-page">
      <div className="title__box">
        <h1>시스템 점검</h1>
      </div>
      <div className="message__box">
        <span>{message}</span>
      </div>
      <div className="button__box">
        <button
          className="cancel"
          onClick={() => {
            Utils.windowClose(queryObject);
          }}
        >
          닫기
        </button>
      </div>
    </article>
  );
};
