import queryString from 'query-string';

const ObjectToParams = (object) => {
  return '?' + queryString.stringify(object);
};
const ResultCheck = (res) => {
  if (res && res.status === 200 && res.data && res.data.result) {
    return res.data;
  }
};
const Utils = {
  ObjectToParams: ObjectToParams,
  ResultCheck: ResultCheck,
};

export default Utils;
