import Utils from './Utils';
import AxiosInstance from './AxiosInstance';

const PATH = 'pay/';

const Check = (object) => {
  const url = PATH + 'check';
  return AxiosInstance.get(url + Utils.ObjectToParams(object));
};

const Confirm = (object) => {
  const url = PATH + 'confirm';
  return AxiosInstance.get(url + Utils.ObjectToParams(object));
};

const AsyncPoll = (body) => {
  const url = 'poll/pay';
  return AxiosInstance.post(url, body);
};

const GetPayInfo = (object) => {
  const url = PATH + 'info';
  return AxiosInstance.get(url + Utils.ObjectToParams(object));
};

const Pay = {
  Check: Check,
  AsyncPoll: AsyncPoll,
  Confirm: Confirm,
  GetPayInfo: GetPayInfo,
};

export default Pay;
