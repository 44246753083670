import React, { useState } from 'react';
import { AlertContext } from '../contexts';

export const AlertProvider = (props) => {
  const { children } = props;
  const [alert, setAlert] = useState({
    open: false,
    message: '',
  });

  const updateAlert = (value) => {
    setAlert(value);
  };

  return (
    <AlertContext.Provider
      value={{
        alert: alert,
        updateAlert: updateAlert,
      }}
    >
      {children}
    </AlertContext.Provider>
  );
};
