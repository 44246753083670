import React, { useContext } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import { LoadingContext } from '../contexts';

const ColorCircularProgress = withStyles((theme) => ({
  colorPrimary: {
    color: '#6e95dd',
  },
}))(CircularProgress);

export const LoadingLayer = (props) => {
  const { children } = props;
  const { loading } = useContext(LoadingContext);

  let layerClassName = 'loading-layer';
  if (!loading) {
    layerClassName += ' hide';
  }

  return (
    <>
      <div className={layerClassName}>
        <ColorCircularProgress />
      </div>
      {children}
    </>
  );
};
