import React, { useContext, useEffect, useState } from 'react';
import API from '../api';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import queryString from 'query-string';
import { LoadingContext } from '../contexts';
import Utils from '../utils';
import { request } from 'klip-sdk';

function checkMobile() {
  var varUA = navigator.userAgent.toLowerCase(); //userAgent 값 얻기

  if (varUA.indexOf('android') > -1) {
    //안드로이드
    return 'android';
  } else if (varUA.indexOf('iphone') > -1 || varUA.indexOf('ipad') > -1 || varUA.indexOf('ipod') > -1) {
    //IOS
    return 'ios';
  } else {
    //아이폰, 안드로이드 외
    return 'other';
  }
}

export const Auth3Page = (props) => {
  const history = useHistory();
  const location = useLocation();
  const deviceType = checkMobile();
  const queryObject = queryString.parse(location.search);
  const { updateLoading } = useContext(LoadingContext);
  console.log('queryObject', queryObject);

  useEffect(() => {
    console.log('deviceType', deviceType);
  }, []);

  const requestKlip = () => {
    console.log('requestKlip');
    updateLoading(true);
    API.Link.Check(queryObject).then(
      (res) => {
        const result = API.Utils.ResultCheck(res);
        console.log('Check result', result);
        if (result) {
          // poll request and tehn deep link
          let pollParams = {
            ...queryObject,
            requestKey: result.prepareKey,
            tempLinkSequence: result.tempLinkSequence,
          };
          API.Link.AsyncPoll(pollParams).then(
            (res) => {
              console.log('AsyncPoll res', res);
              updateLoading(false);

              if (deviceType === 'other') {
                history.push({
                  pathname: 'qr_result',
                  search: new URLSearchParams(pollParams).toString(),
                });
              } else {
                history.push({
                  pathname: 'result',
                  search: new URLSearchParams(pollParams).toString(),
                });
                request(result.prepareKey);
              }
            },
            (err) => {
              updateLoading(false);
              Utils.errorHandler(queryObject, err);
            },
          );
        }
      },
      (err) => {
        updateLoading(false);
        Utils.errorHandler(queryObject, err);
      },
    );
  };

  return (
    <article className="auth-page">
      <section className="scroll__container">
        <PerfectScrollbar>
          <article>
            <section>
              <div style={{ marginBottom: 27, marginTop: 37 }} className="item group"></div>
              <div className="text__box">
                <h1 className="blue">클립 연결하고</h1>
                <h1>토큰 적립부터 결제까지</h1>
              </div>
              <div style={{ marginTop: 15 }} className="text__box">
                <h3>한경 서비스에서 준비한 이벤트에</h3>
                <h3>참여하고 토큰을 획득하세요!</h3>
              </div>
              <div style={{ marginTop: 30 }} className="item klay"></div>
              <div className="item dash-line"></div>
            </section>
            <section style={{ marginTop: 40 }}>
              <div className="text__box">
                <h2 className="blue">클립</h2>
                <h2>연결 가이드</h2>
              </div>
              <div className="guide-text__box">
                <h4>01</h4>
                <span>
                  카카오계정으로 <em>클립 회원가입</em> 진행
                </span>
              </div>
              <div style={{ top: 150 }} className="line"></div>
              <div style={{ marginTop: 30 }} className="item guide_1"></div>
            </section>
            <section style={{ marginTop: 10 }}>
              <div className="guide-text__box">
                <h4>02</h4>
                <span>
                  정보제공요청 확인 후, <em>다음 버튼</em> 클릭
                </span>
              </div>
              <div style={{ top: 90 }} className="line"></div>
              <div style={{ marginTop: 30 }} className="item guide_2"></div>
            </section>
            <section style={{ marginTop: 10 }}>
              <div className="guide-text__box">
                <h4>03</h4>
                <span>
                  한경서비스로 돌아와 <em>연결확인 버튼</em> 클릭
                </span>
              </div>
              <div style={{ top: 90 }} className="line"></div>
              <div style={{ marginTop: 30 }} className="item guide_3"></div>
            </section>
            <div style={{ marginTop: 50 }} className="secten-end__box">
              <div className="line"></div>
            </div>
            <section>
              <div style={{ marginTop: 15, marginBottom: 10 }} className="ico klip"></div>
              <div className="klip-text__box" style={{ marginBottom: 50 }}>
                <h1>클립이란?</h1>
                <p>Klip은 안전하고 투명한 블록체인 서비스의 혜택을 제공하는 새로운 디지털 지갑입니다.</p>
              </div>
              <div className="klip-text__box" style={{ marginBottom: 50 }}>
                <h1 className="blue">토큰</h1>
                <p>
                  블록체인 서비스에서 사용되는 자산으로, <br></br> 클립에서 다양한 디지털 자산의 기축 역할을 하는 KLAY와
                  블록체인 서비스에서 발행하는 서비스 토큰이 있습니다.
                </p>
              </div>
            </section>
          </article>
        </PerfectScrollbar>
      </section>

      <section className="fixed__container">
        <div className="klip__button" onClick={requestKlip}>
          <span>카톡으로 클립 연결하기</span>
          <div className="ico klip"></div>
        </div>
      </section>
    </article>
  );
};
