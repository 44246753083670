import React, { useEffect, useContext } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import Utils from '../utils';
import { PayInfoContext } from '../contexts';

function checkMobile() {
  var varUA = navigator.userAgent.toLowerCase(); //userAgent 값 얻기

  if (varUA.indexOf('android') > -1) {
    //안드로이드
    return 'android';
  } else if (varUA.indexOf('iphone') > -1 || varUA.indexOf('ipad') > -1 || varUA.indexOf('ipod') > -1) {
    //IOS
    return 'ios';
  } else {
    //아이폰, 안드로이드 외
    return 'other';
  }
}

const checkSearchStringExist = (str) => {
  const splitStringList = str.split('?');
  if (splitStringList.length === 1) {
    return false;
  } else {
    return true;
  }
};

export const ErrorPage = (props) => {
  const history = useHistory();
  const location = useLocation();
  const queryObject = queryString.parse(location.search);
  const deviceType = checkMobile();
  let code = queryObject.code;
  const { payInfo } = useContext(PayInfoContext);
  console.log('deviceType', deviceType);
  console.log('code', code);
  console.log('payInfo', payInfo);

  if (code === '1002') {
    return (
      <article className="result-page" style={{ alignItems: 'center', background: '#ffffff' }}>
        <section className="custom__box">
          <div className="item logo_hk_klip" style={{ marginTop: 28 }}></div>
        </section>
        <section className="custom__box">
          <div className="item blue_link" style={{ marginTop: 70 }}></div>
          <b
            className="blue-text"
            style={{
              marginTop: 25,
            }}
          >
            클립이 이미 연결돼 있으며, <br></br> 성공적으로 이벤트 참여가 완료됐습니다.
          </b>
        </section>
        <section className="custom__box" style={{ marginTop: 40 }}>
          <div className="item mulitple_coin"></div>
          <span style={{ marginTop: 10 }} className="black-text">
            클레이 현황은 '카카오 클립'또는 <br></br> <b>'모바일한경'</b>에서 확인할 수 있습니다.
          </span>
        </section>
        <section className="custom__box" style={{ flex: 1, marginBottom: 40, justifyContent: 'flex-end' }}>
          <button
            className="cancel-btn"
            onClick={() => {
              Utils.windowClose(queryObject);
            }}
          >
            닫기
          </button>
        </section>
      </article>
    );
  }
  return (
    <article className="result-page">
      <section className="message__box">
        <div className="img__box">
          <section className="logo">
            <div className="item logo-hankyungmedia"></div>
          </section>
          <section className="check">
            <div className="ico error-big"></div>
          </section>
        </div>
        <div className="text__box">
          <p>{queryObject.message || '알 수 없는 오류가 발생했습니다.'}</p>
        </div>
      </section>
      <section className="button__box">
        <button
          className="cancel"
          onClick={() => {
            let params = null;
            if (queryObject.code && queryObject.message) {
              params = {
                code: queryObject.code,
                message: queryObject.message,
              };
            }
            if (payInfo) {
              let payObject = Utils.payInfoToParams(payInfo);
              console.log('payObject', payObject);

              params = { ...params, ...payObject };
            }
            console.log('params', params);
            Utils.windowClose(queryObject, params);
          }}
        >
          닫기
        </button>
      </section>
    </article>
  );
};
