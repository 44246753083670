import Utils from './Utils';
import { BASE_URL } from './Config';
import AxiosInstance from './AxiosInstance';

const PATH = 'link/';

const Check = (object) => {
  const url = PATH + 'check';
  return AxiosInstance.get(url + Utils.ObjectToParams(object));
};

const Exist = (object) => {
  const url = PATH + 'exist';

  return AxiosInstance.get(url + Utils.ObjectToParams(object));
};

const Confirm = (object) => {
  const url = PATH + 'confirm';
  return AxiosInstance.get(url + Utils.ObjectToParams(object));
};

const AsyncPoll = (body) => {
  const url = 'poll/link';
  return AxiosInstance.post(url, body);
};

const Link = {
  Check: Check,
  AsyncPoll: AsyncPoll,
  Exist: Exist,
  Confirm: Confirm,
};

export default Link;
