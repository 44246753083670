import React from 'react';
import { Route, Link } from 'react-router-dom';
import queryString from 'query-string';
import Utils from '../utils';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

export const WalletLayout = (props) => {
  let { Component, ...rest } = props;
  const location = useLocation();
  const queryObject = queryString.parse(location.search);
  let serviceGroupId = null;
  if (queryObject.serviceGroupId) {
    serviceGroupId = queryObject.serviceGroupId.toLocaleLowerCase();
  }
  return (
    <div className="wallet-layout" id="wallet-layout">
      {process.env.REACT_APP_ENV === 'production' && (
        <Helmet>
          {serviceGroupId === 'com' ? (
            <script type="text/javascript" src="//static.hankyung.com/js/ga/googleTagManager.js" async="true"></script>
          ) : serviceGroupId === 'paper' ? (
            <script async src="https://www.googletagmanager.com/gtag/js?id=UA-109163096-2"></script>
          ) : serviceGroupId === 'tv' ? (
            <script async src="https://www.googletagmanager.com/gtag/js?id=UA-115983265-1"></script>
          ) : null}
          {serviceGroupId === 'com' ? null : serviceGroupId === 'paper' ? (
            <script>
              {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'UA-109163096-2');
              `}
            </script>
          ) : serviceGroupId === 'tv' ? (
            <script>
              {`
             window.dataLayer = window.dataLayer || [];
             function gtag(){dataLayer.push(arguments);}
             gtag('js', new Date());
             gtag('config', 'UA-115983265-1');
              `}
            </script>
          ) : null}
          {/* 한경멤버스 구글 애널리틱스*/}
          {serviceGroupId === 'paper' && (
            <script async src="https://www.googletagmanager.com/gtag/js?id=UA-163980838-1"></script>
          )}
          {serviceGroupId === 'paper' && (
            <script>
              {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
          
            gtag('config', 'UA-163980838-1');
              `}
            </script>
          )}
          {/* 한경멤버스 구글 애즈*/}
          {serviceGroupId === 'paper' && (
            <script async src="https://www.googletagmanager.com/gtag/js?id=AW-746154598"></script>
          )}
          {serviceGroupId === 'paper' && (
            <script>
              {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              
              gtag('config', 'AW-746154598');
              `}
            </script>
          )}
          {/* 한경멤버스 페이스북 */}
          {serviceGroupId === 'paper' && (
            <script>
              {`
              !function(f,b,e,v,n,t,s)
              {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
              n.callMethod.apply(n,arguments):n.queue.push(arguments)};
              if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
              n.queue=[];t=b.createElement(e);t.async=!0;
              t.src=v;s=b.getElementsByTagName(e)[0];
              s.parentNode.insertBefore(t,s)}(window, document,'script',
              'https://connect.facebook.net/en_US/fbevents.js');
              fbq('init', '653129498580542');
              fbq('track', 'PageView');    
            `}
            </script>
          )}
          {serviceGroupId === 'paper' && (
            <noscript>
              {`
              <img
                height="1"
                width="1"
                style="display:none"
                src="https://www.facebook.com/tr?id=653129498580542&ev=PageView&noscript=1"
              />
              `}
            </noscript>
          )}
          {/* 한경멤버스 카카오 */}
          {serviceGroupId === 'paper' && (
            <script type="text/javascript" charset="UTF-8" src="//t1.daumcdn.net/adfit/static/kp.js"></script>
          )}
          {serviceGroupId === 'paper' && (
            <script type="text/javascript">{`
            kakaoPixel('4650548041940618972').pageView();
            `}</script>
          )}
          {/* 한경멤버스 네이버 */}
          {serviceGroupId === 'paper' && <script type="text/javascript" src="//wcs.naver.net/wcslog.js"></script>}
          {serviceGroupId === 'paper' && (
            <script type="text/javascript">
              {`
              if (!wcs_add) var wcs_add={};
              wcs_add["wa"] = "s_2657d9f8d15f";
              if (!_nasa) var _nasa={};
              wcs.inflow();
              wcs_do(_nasa);
          `}
            </script>
          )}
        </Helmet>
      )}
      <article className="wallet-header">
        <div className="item logo-hankyungmedia"></div>
        <div className="button__box">
          <div
            className="ico black_close"
            onClick={() => {
              Utils.windowClose(queryObject);
            }}
          ></div>
        </div>
      </article>
      <article className="content__container" id="content__container">
        <Route {...rest} render={(matchProps) => <Component {...matchProps} />} />
      </article>
    </div>
  );
};
