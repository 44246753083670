import Utils from './Utils';
import axios from 'axios';
import { BASE_URL } from './Config';
import AxiosInstance from './AxiosInstance';

const PATH = 'wallet/';

const getBalance = (object) => {
  const url = PATH + 'balance';
  return AxiosInstance.get(url + Utils.ObjectToParams(object));
};

const getTransfer = (object) => {
  const url = PATH + 'transfer';
  return AxiosInstance.get(url + Utils.ObjectToParams(object));
};

const getReward = (object) => {
  const url = PATH + 'reward';
  return AxiosInstance.get(url + Utils.ObjectToParams(object));
};

const getSerivceGroupList = () => {
  const url = PATH + 'serviceGroup';
  return AxiosInstance.get(url);
};

const Wallet = {
  getBalance: getBalance,
  getTransfer: getTransfer,
  getReward: getReward,
  getSerivceGroupList: getSerivceGroupList,
};

export default Wallet;
