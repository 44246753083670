import React, { useState } from 'react';
import { LoadingContext } from '../contexts';

export const LoadingProvider = (props) => {
  const { children } = props;
  const [loading, setLoading] = useState(false);

  const updateLoading = (value) => {
    setLoading(value);
  };

  return (
    <LoadingContext.Provider
      value={{
        loading: loading,
        updateLoading: updateLoading,
      }}
    >
      {children}
    </LoadingContext.Provider>
  );
};
