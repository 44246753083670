import Utils from './Utils';
import Link from './Link';
import Wallet from './Wallet';
import Pay from './Pay';

const API = {
  Link: Link,
  Utils: Utils,
  Wallet: Wallet,
  Pay: Pay,
};

export default API;
