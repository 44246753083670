import React from 'react';
import { Router } from './Router';
import './styles/index.scss';
import 'react-perfect-scrollbar/dist/css/styles.css';
import Provider from './Provider';
import { AlertLayer, LoadingLayer } from './layers';
function App() {
  console.log('env', process.env);
  return (
    <div className="App">
      <Provider>
        <LoadingLayer>
          <AlertLayer>
            <Router></Router>
          </AlertLayer>
        </LoadingLayer>
      </Provider>
    </div>
  );
}

export default App;
