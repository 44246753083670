import React, { useEffect, useContext } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import API from '../api';
import queryString from 'query-string';
import { LoadingContext, AlertContext } from '../contexts';
import Utils from '../utils';

function checkMobile() {
  var varUA = navigator.userAgent.toLowerCase(); //userAgent 값 얻기

  if (varUA.indexOf('android') > -1) {
    //안드로이드
    return 'android';
  } else if (varUA.indexOf('iphone') > -1 || varUA.indexOf('ipad') > -1 || varUA.indexOf('ipod') > -1) {
    //IOS
    return 'ios';
  } else {
    //아이폰, 안드로이드 외
    return 'other';
  }
}

export const RetryPage = (props) => {
  const history = useHistory();
  const location = useLocation();
  const queryObject = queryString.parse(location.search);
  const { updateLoading } = useContext(LoadingContext);
  const { updateAlert } = useContext(AlertContext);

  const deviceType = checkMobile();

  useEffect(() => {
    console.log('deviceType', deviceType);
  }, []);

  const requestKlip = () => {
    console.log('requestKlip');
    updateLoading(true);

    API.Link.Check(queryObject).then(
      (res) => {
        const result = API.Utils.ResultCheck(res);
        console.log('Check result', result);

        if (result) {
          // poll request and tehn deep link
          let pollParams = {
            ...queryObject,
            requestKey: result.prepareKey,
            tempLinkSequence: result.tempLinkSequence,
          };
          API.Link.AsyncPoll(pollParams).then(
            (res) => {
              updateLoading(false);

              console.log('AsyncPoll res', res);
              if (deviceType === 'other') {
                history.push({
                  pathname: 'qr_result',
                  search: new URLSearchParams(pollParams).toString(),
                });
              } else {
                history.push({
                  pathname: 'result',
                  search: new URLSearchParams(pollParams).toString(),
                });

                //정규식 딥링크
                window.open(`https://klipwallet.com/?target=/a2a?request_key=${result.prepareKey}`, '_self');
              }
            },
            (err) => {
              console.log('err', err);
              updateLoading(false);
              Utils.errorHandler(queryObject, err);
            },
          );
        }
      },
      (err) => {
        updateLoading(false);
        Utils.errorHandler(queryObject, err);
      },
    );
  };

  return (
    <article className="result-page">
      <section className="message__box">
        <div className="img__box">
          <section className="logo">
            <div className="item logo-hankyungmedia"></div>
          </section>
          <section className="check">
            <div className="ico error-big"></div>
          </section>
        </div>
        <div className="text__box">
          <h1>
            알 수 없는 오류가 발생했습니다.<br></br>
            확인 후 재시도 해주세요.
          </h1>
        </div>
      </section>
      <section className="button__box">
        <button style={{ marginBottom: 20 }} onClick={requestKlip}>
          재시도
        </button>
        <button
          className="cancel"
          onClick={() => {
            Utils.windowClose(queryObject);
          }}
        >
          취소
        </button>
      </section>
    </article>
  );
};
