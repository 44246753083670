import React, { useState, useEffect, useRef } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Utils from '../utils';
import API from '../api';
import queryString from 'query-string';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const ColorCircularProgress = withStyles((theme) => ({
  colorPrimary: {
    color: '#6e95dd',
  },
}))(CircularProgress);

const WhiteCircularProgress = withStyles((theme) => ({
  colorPrimary: {
    color: '#ffffff',
  },
}))(CircularProgress);

const listToGroupData = (list) => {
  console.log('listToGroupData', list);
  let lastYYYYMM = null;
  let newObj = {};
  for (let i in list) {
    let listItem = list[i];
    console.log('listItem', listItem);
    const targetYYYYMM = listItem.reg_dt.substr(0, 7);
    if (lastYYYYMM) {
      if (lastYYYYMM === targetYYYYMM) {
        newObj[targetYYYYMM].push(list[i]);
      } else {
        lastYYYYMM = targetYYYYMM;
        newObj[targetYYYYMM] = [list[i]];
      }
    } else {
      lastYYYYMM = targetYYYYMM;
      newObj[targetYYYYMM] = [list[i]];
    }
  }
  return newObj;
};

export const RewardPage = (props) => {
  const history = useHistory();
  const location = useLocation();
  const queryObject = queryString.parse(location.search);
  const [balanceInfo, setBalanceInfo] = useState(null);
  const [rewardList, setRewardList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [noMore, setNoMore] = useState(false);
  // const [displayList, setDisplayList] = useState({});
  const [startDate, setStartDate] = useState(new Date());
  const reactDatePicker = useRef(null);

  useEffect(() => {});

  useEffect(() => {
    console.log('queryObject', queryObject);

    API.Wallet.getBalance(queryObject).then(
      (res) => {
        let result = API.Utils.ResultCheck(res);
        console.log('getBalance result', result);
        let newBalanceInfo = {
          current: Utils.pebIntToKlay(result.currentBalance),
          reward: Utils.pebIntToKlay(result.rewardBalance),
        };
        setBalanceInfo(newBalanceInfo);
      },
      (err) => {
        Utils.errorHandler(queryObject, err);
      },
    );
    setLoading(true);
    let rewardParams = {
      memberId: queryObject.memberId,
      memberGroupId: queryObject.memberGroupId,
      offset: 0,
      token: queryObject.token,
    };
    API.Wallet.getReward(rewardParams).then(
      (res) => {
        let result = API.Utils.ResultCheck(res);
        console.log('getReward result', result);
        setRewardList([...rewardList, ...result.list]);
        setLoading(false);
        if (result.list.length === 0) {
          setNoMore(true);
        }
      },
      (err) => {
        Utils.errorHandler(queryObject, err);
      },
    );
  }, []);

  useEffect(() => {
    console.log('rewardList', rewardList);
    // setDisplayList(listToGroupData(rewardList));
  }, [rewardList]);

  const onYReachEnd = () => {
    console.log('onYReachEnd');
    if (rewardList.length === 0 || rewardList.length < 10) {
      return;
    }
    if (noMore) {
      console.log('no More');
      return;
    }
    if (loading) {
      console.log('return now loading');

      return;
    } else {
      console.log('setLoading');
      setLoading(true);
    }
    let rewardParams = {
      memberId: queryObject.memberId,
      memberGroupId: queryObject.memberGroupId,
      offset: parseInt(rewardList.length),
      token: queryObject.token,
    };
    console.log('rewardParams', rewardParams);
    API.Wallet.getReward(rewardParams).then(
      (res) => {
        let result = API.Utils.ResultCheck(res);
        console.log('getReward result', result);
        setRewardList([...rewardList, ...result.list]);
        setLoading(false);
        if (result.list.length === 0) {
          setNoMore(true);
        }
      },
      (err) => {
        Utils.errorHandler(queryObject, err);
      },
    );
  };

  return (
    <article className="reward-page">
      <PerfectScrollbar onYReachEnd={onYReachEnd}>
        <section className="info__box">
          {!balanceInfo ? (
            <article className="klay-card">
              <div className="loading__box">
                <WhiteCircularProgress />
              </div>
            </article>
          ) : (
            <article className="klay-card">
              <div className="balance">
                <div className="value">
                  <em> {balanceInfo && balanceInfo.reward}</em>
                </div>
                <span className="unit">KLAY</span>
              </div>
            </article>
          )}
        </section>
        <section className="transfer-header">
          <h1>적립 예정 내역</h1>
        </section>
        {/* <section className="month-picker__box">
          <div className="custom-month-picker">
            <div className="show-data">
              <div
                className="ico arrow-button-left"
                style={{ marginRight: 40 }}
                onClick={() => {
                  let oneMonthAgo = new Date(startDate.getFullYear(), startDate.getMonth() - 1, startDate.getDate());
                  setStartDate(oneMonthAgo);
                }}
              ></div>
              <div className="react-date-picker__box">
                <DatePicker
                  ref={reactDatePicker}
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  dateFormat="yyyy년 MM월"
                  showMonthYearPicker
                />
              </div>
              <div
                className="ico arrow-button-right"
                style={{ marginLeft: 40 }}
                onClick={() => {
                  let oneMonthAgo = new Date(startDate.getFullYear(), startDate.getMonth() + 1, startDate.getDate());
                  setStartDate(oneMonthAgo);
                }}
              ></div>
            </div>
            <div className="right__box">
              <div
                className="ico calendar"
                onClick={() => {
                  console.log('reactDatePicker.current', reactDatePicker.current);
                  reactDatePicker.current.setOpen(true);
                }}
              ></div>
            </div>
          </div>
        </section> */}

        <section className="reward-list__box">
          {rewardList && rewardList.length > 0 && (
            <ul>
              {rewardList.map((row, index) => {
                return (
                  <li className={row.type}>
                    <div className="header">
                      <span>{Utils.dbDateToMMDD(row.reserve_dt)}</span>
                    </div>
                    <div className="sub">
                      <span>{row.mbr_grp_id === 'hk' ? '한경미디어그룹' : '한경TV'} </span>
                      <span style={{ color: '#00aca5' }}>적립 예정</span>
                    </div>
                    <div className="main">
                      <span>{row.name}</span>
                      <b>
                        +{Utils.pebIntToKlay2(row.amount)}
                        <em> KLAY</em>
                      </b>
                    </div>
                  </li>
                );
              })}
            </ul>
          )}
          {rewardList.length === 0 && !loading && (
            <div className="empty__box">
              <div className="ico info-blue"></div>
              <span style={{ marginTop: 20 }}>적립 예정 내역이 없습니다.</span>
            </div>
          )}
        </section>
        {loading && (
          <div className="loading__box">
            <ColorCircularProgress />
          </div>
        )}
      </PerfectScrollbar>
    </article>
  );
};
