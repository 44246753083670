import moment from 'moment-timezone';
import BigNumber from 'bignumber.js';
import queryString from 'query-string';

const pebIntToKlay = (value) => {
  console.log('pebIntToKlay value', value);

  if (Number.isInteger(value)) {
    // return parseFloat(value / 1000000000000000000);
    return Math.floor(parseFloat(value / 1000000000000000000) * 100000) / 100000;
  } else {
    return null;
  }
};

const pebIntToKlay2 = (value) => {
  console.log('pebIntToKlay2 value', value);
  let parseValue = Number(value);
  if (Number.isInteger(parseValue)) {
    return Math.floor(parseFloat(parseValue / 1000000000000000000) * 100000) / 100000;
  } else {
    return null;
  }
};

const dbDateToMMDD = (date) => {
  if (date) {
    return moment.utc(date).format('MM.DD');
  } else {
    return null;
  }
};

const YYYYMMToKorean = (date) => {
  let retunValue = date.replace('-', '년 ');
  retunValue += '월';
  return retunValue;
};

const errorHandler = (queryObject, err) => {
  let errorMessage = '';
  if (err.response && err.response.data && err.response.data.message) {
    errorMessage = err.response.data.message;
  }
  let errorCode = '';
  if (err.response && err.response.data && err.response.data.code) {
    errorCode = err.response.data.code;
  }
  let errorParams = {
    ...queryObject,
    message: errorMessage,
    code: errorCode,
  };
  let searchString = new URLSearchParams(errorParams).toString();
  console.log('searchString', searchString);
  window.location.href = '/error?' + searchString;
};

const windowClose = (queryObject, params) => {
  console.log('windowClose queryObject', queryObject);
  // webView 면서 failUrl이 있을 경우에는 windowClose를 failUrl로 이동
  if (queryObject.webView && queryObject.failUrl) {
    window.location.href = generateUrlWithParams(queryObject.failUrl, params);
  } else if (queryObject.failUrl) {
    // failUrl만 있을 경우  window.opener.location.href 이동해주고 끔
    window.opener.location.href = generateUrlWithParams(queryObject.failUrl, params);
    window.close();
  } else {
    // 팝업 케이스
    window.close();
  }
};

const requestSuccess = (queryObject, params) => {
  if (queryObject.webView && queryObject.redirectUrl) {
    window.location.href = generateUrlWithParams(queryObject.redirectUrl, params);
  } else if (queryObject.redirectUrl) {
    window.opener.location.href = generateUrlWithParams(queryObject.redirectUrl, params);
    window.close();
  } else {
    // 팝업 케이스
    window.close();
  }
};

const checkSearchStringExist = (str) => {
  const splitStringList = str.split('?');
  if (splitStringList.length === 1) {
    return false;
  } else {
    return true;
  }
};

const buildEncodingUrl = (url) => {
  const splitStringList = url.split('?');
  if (splitStringList.length > 1) {
    console.log('params exist', splitStringList);
    let originUrl = splitStringList[0];
    console.log('originUrl', originUrl);

    const new_url = new URL(url);
    console.log('new_url', new_url);
    const parsed = queryString.parse(new_url.search);
    console.log('parsed', parsed);
    const stringified = queryString.stringify(parsed);
    console.log('stringified', stringified);
    return originUrl + '?' + stringified;
  } else {
    console.log('params none');
    return url;
  }
};

const generateUrlWithParams = (url, obj) => {
  var str = [];
  for (var p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
    }
  let encodingUrl = buildEncodingUrl(url);

  console.log('str', str);
  if (str.length > 0) {
    if (checkSearchStringExist(url)) {
      return encodingUrl + '&' + str.join('&');
    } else {
      return encodingUrl + '?' + str.join('&');
    }
  } else {
    return encodingUrl;
  }
};

const payInfoToParams = (obj) => {
  return {
    prepareKey: obj.pay_info_seq,
    mallType: obj.mall_type,
    mallOrderNo: obj.mall_order_num,
    productName: obj.product_name,
    orderDate: obj.order_dt,
    wonPrice: obj.won_price,
    closingPrice: obj.closing_price,
    amount: pebToKlay(obj.amount),
    memberName: obj.mbr_name,
    etc: obj.etc,
  };
};

const pebToKlay = (value) => {
  // console.log('pebIntToKlay value', value);
  if (value) {
    let pebAmount = new BigNumber(value);
    let klayUnit = new BigNumber(1e18);
    let klayAmount = pebAmount.dividedBy(klayUnit).toString(10);
    return klayAmount;
  } else {
    return '0';
  }
};

const Utils = {
  pebIntToKlay: pebIntToKlay,
  pebIntToKlay2: pebIntToKlay2,
  dbDateToMMDD: dbDateToMMDD,
  YYYYMMToKorean: YYYYMMToKorean,
  errorHandler: errorHandler,
  windowClose: windowClose,
  requestSuccess: requestSuccess,
  pebToKlay: pebToKlay,
  payInfoToParams: payInfoToParams,
};

export default Utils;
